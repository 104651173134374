import * as React from "react";
import PropTypes from "prop-types";

function SvgHotel(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V10H19V9C19 7.89543 18.1046 7 17 7H15C13.8954 7 13 7.89543 13 9V10H11V9C11 7.89543 10.1046 7 9 7H7C5.89543 7 5 7.89543 5 9V10H3V6ZM4 11C2.34315 11 1 12.3431 1 14V18.7778V19.8889C1 20.5025 1.49746 21 2.11111 21C2.72476 21 3.22222 20.5025 3.22222 19.8889V18.7778H12H20.7778V19.8889C20.7778 20.5025 21.2752 21 21.8889 21C22.5025 21 23 20.5025 23 19.8889V18.7778V14C23 12.3431 21.6569 11 20 11H4Z"
        fill={props.color ?? "#151209"}
      />
    </svg>
  );
}

SvgHotel.propTypes = {
  color: PropTypes.string,
};

export default SvgHotel;
