export * from "@tint_fe/redux-utils/src/global/globalActions";
import * as types from "./globalActionTypes";

export const fetchPricesRequest = (body) => ({
  type: types.FETCH_PRICES_REQUEST,
  payload: body,
});

export const setLoadingAction = (data) => ({
  type: types.SET_LOADING_ACTION,
  payload: data,
});

export const setDropOffLocationAction = (point) => ({
  type: types.SET_DROP_OFF_LOCATION_ACTION,
  payload: point,
});

export const setStepAction = (step) => ({
  type: types.SET_STEP_ACTION,
  payload: step,
});

export const setBookingType = (payload) => ({
  type: types.SET_BOOKING_TYPE,
  payload: payload,
});

export const updateRatesAction = (payload) => ({
  type: types.UPDATE_RATES_REQUEST,
  payload,
});

export const setChangedRatesAction = (payload) => ({
  type: types.UPDATE_RATES_SUCCESS,
  payload,
});

export const saveRootIdAction = (payload) => ({
  payload,
  type: types.SAVE_ROOT_ID,
});

export const saveSelectedPaymentMethod = (payload) => ({
  payload,
  type: types.SAVE_SELECTED_PAYMENT_METHOD,
});

export const setRefetchPricesDueCurrencyChange = (payload) => ({
  payload,
  type: types.SET_REFETCH_PRICES_DUE_CURRENCY_CHANGE,
});

export const setShouldPreselectSplitPaymentAction = (payload) => ({
  payload,
  type: types.SET_SHOULD_PRESELECT_SPLIT_PAYMENT,
});

export const toggleRequestQuoteModal = (payload) => ({
  type: types.TOGGLE_REQUEST_QUOTE_MODAL,
  payload,
});
