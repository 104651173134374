import React from "react";
import T from "prop-types";
import { ArrowRight, Bookmark16 } from "../../assets/icons";
import { getQuotePackageSubmitted, handleABTestingFeatures, parseClasses } from "@tint_fe/helpers";
import { useBreakPoints } from "@tint_fe/hooks";

const SummaryRequestQuote = ({ onClick, orderNumber }) => {
  const { elCls } = parseClasses({ base: "summary" });
  const { isMd } = useBreakPoints();

  const searchParams = new URLSearchParams(window.location.search);
  const source = searchParams.get("source");
  const quotePkSubmitted = getQuotePackageSubmitted();
  const { requestQuote: ABTestRequestQuote } = handleABTestingFeatures();

  if ((source === "incomplete_email" && orderNumber === quotePkSubmitted) || !ABTestRequestQuote) {
    return null;
  }

  return (
    <div className={elCls("request-quote")} onClick={onClick}>
      <div className={elCls("request-quote-row")}>
        <Bookmark16 />
        <p className={elCls("request-quote__text")}>{"Save booking and send me the quote by email"}</p>
      </div>
      {!isMd && <ArrowRight />}
    </div>
  );
};

SummaryRequestQuote.defaultProps = {
  onClick: () => null,
};

SummaryRequestQuote.propTypes = {
  onClick: T.func,
  orderNumber: T.string,
};

export default SummaryRequestQuote;
