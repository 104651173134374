import React from "react";
import T from "prop-types";
import { useSelector } from "react-redux";

import { join, bookingTypes } from "@tint_fe/helpers";
import { useTranslation, useRouterMatch, useBreakPoints } from "@tint_fe/hooks";

import { useRedirectFromPayment, usePaymentLayout, useDiscountInfo } from "../../hooks";
import TintProsBooking from "../components/TintProsBooking";
import { timeToShowTimer } from "../../helpers/constants";
import RoomsTimer from "../components/RoomsTimer";
import { PAYMENT } from "../router/routes";

const BookingLayout = ({ children, dataTestid, expireAt, isFreeCancellation, summary, timeToShow }) => {
  const { showDiscount } = useDiscountInfo();
  const { promotion_image_url = "" } = useSelector(({ global }) => global?.integration);

  const { build_type } = useSelector(({ order }) => order.data);

  const { isRtl } = useTranslation();
  const isPaymentLayout = usePaymentLayout();
  const { isMd } = useBreakPoints();
  const isPaymentPage = useRouterMatch(PAYMENT);
  const { goToPrevPath, paymentExpireAt, isPrevStepPayment } = useRedirectFromPayment();

  const isTour = build_type && build_type === bookingTypes.TOUR;
  const isActivity = build_type && build_type === bookingTypes.ACTIVITY;

  const preventShowNotify = (isTour || isActivity) && (isPrevStepPayment ?? true);
  const showTimer = isPaymentPage;
  const getTimeToShow = () => {
    if (isPaymentPage) return timeToShowTimer.PAYMENT;

    return timeToShow || timeToShowTimer.ORDER;
  };

  const getTimerExpiredHandler = () => {
    if (isPaymentPage) {
      return () => {
        goToPrevPath();
      };
    }
  };

  const showBannerDiscount = showDiscount && !!promotion_image_url;
  const showMdDiscount = isMd && showBannerDiscount;
  const showLgDiscount = !isMd && showBannerDiscount;

  return (
    <div data-testid={dataTestid} dir={isRtl ? "rtl" : "auto"} className={`row order-summary__wrapper ${isRtl}`}>
      <div className="col col-12 col-md-7">
        {children}
        {showLgDiscount && (
          <div className={"order-summary__svg-banner"}>
            <img src={promotion_image_url} alt="promotion" />
          </div>
        )}
      </div>
      <div className="col col-12 col-md-5">
        <div className={join(["order-summary__sticky", (isPaymentLayout || isMd) && "payment-page"], " ")}>
          {!isPaymentLayout && !isMd && summary}

          <RoomsTimer
            onTimeExpired={getTimerExpiredHandler()}
            expireAt={isPaymentPage && isTour ? paymentExpireAt : expireAt}
            timeToShow={getTimeToShow()}
            preventShowNotify={preventShowNotify}
            hideTimer={!showTimer}
          />

          {showMdDiscount && (
            <div className={"order-summary__svg-banner"}>
              <img src={promotion_image_url} alt="promotion" />
            </div>
          )}

          <TintProsBooking {...(isPaymentLayout && { className: "mg-0" })} isFreeCancellation={isFreeCancellation} />
        </div>
      </div>
    </div>
  );
};

BookingLayout.propTypes = {
  children: T.node.isRequired,
  dataTestid: T.string,
  expireAt: T.number,
  isFreeCancellation: T.bool,
  onTimeExpired: T.func,
  summary: T.node.isRequired,
  timeToShow: T.number,
};

export default BookingLayout;
