import React from "react";
import { useSelector } from "react-redux";

import { useAgentCommission, useRouterMatch } from "@tint_fe/hooks";

import OrderSummaryView from "../../../components/OrderSummaryView";
import { useCheckIfBreakfastIncluded, useSPSummaryInfo } from "../hooks";

import {
  SMART_PACKAGE_CHECKOUT,
  SMART_PACKAGE_CUSTOMER,
  SMART_PACKAGE_PAYMENT,
  SMART_PACKAGE_SELECT_HOTELS,
} from "../../../router";
import { selectIfCustomPrice } from "../../../../redux/sharedSelectors";
import { isEmpty } from "lodash";
import { QueryString } from "@tint_fe/helpers";
import HotelsItinerary from "../SmartPackageCustomerInfo/HotelsItinerary";
import { useHistoryNavigation, useReduxAction } from "../../../../hooks";
import { setReplaceHotelIdAction, updateSelectedHotelsAction } from "../../../../redux/smartPackage/smartPackageAction";
import { toggleRequestQuoteModal } from "../../../../redux/global/globalActions";
import { formatPiToSelectedHotel, getHotelsFromPi, getItemsWithHotels } from "../helpers";

const SPOrderSummary = () => {
  const { push } = useHistoryNavigation();
  const setReplaceHotelId = useReduxAction(setReplaceHotelIdAction);
  const setHotels = useReduxAction(updateSelectedHotelsAction);
  const toggleQuoteModal = useReduxAction(toggleRequestQuoteModal);

  const orderData = useSelector(({ order }) => order.data);
  const { showAgentCommission, showAgentPays, isCusomerPAC, isUserPAC } = useAgentCommission({ order: orderData });
  const { display_name: displayName } = useSelector((state) => state.smartPackage.data);
  const price = useSelector(({ global }) => global?.price);
  const integration = useSelector(({ global }) => global?.integration);
  const isCustomPrice = useSelector(selectIfCustomPrice);
  const spChargeCurrency = useSelector(({ smartPackage }) => smartPackage?.charge_currency);
  const checkIfBreakfastIncluded = useCheckIfBreakfastIncluded();
  const breakfastIncluded = checkIfBreakfastIncluded();
  const spOrIntegrationCurrency = spChargeCurrency ?? integration?.charge_currency;
  const currency = isEmpty(orderData) ? spOrIntegrationCurrency : price?.charge_currency ?? spOrIntegrationCurrency;
  const missedBuckets = orderData?.missed_buckets || [];

  const autoAccommodation = true;
  const hotels = getItemsWithHotels(orderData?.product_items ?? []);
  const allHotelItems = getHotelsFromPi(orderData?.product_items ?? []);
  const selectedHotels = formatPiToSelectedHotel(allHotelItems);

  const {
    requestedPrice,
    totalPrice,
    lineItems,
    rooms,
    hints,
    roundedTotalPrice,
    startLocation,
    endLocation,
    hidePricesSection,
  } = useSPSummaryInfo();

  const isCheckout = useRouterMatch(SMART_PACKAGE_CHECKOUT);
  const isCustomer = useRouterMatch(SMART_PACKAGE_CUSTOMER);
  const isPayment = useRouterMatch(SMART_PACKAGE_PAYMENT);

  const hideLineItems = (isCustomer || isPayment) && hidePricesSection;

  const onReplaceHotels = ({ hotel_id, pi_id }) => {
    const newSearch = QueryString.stringify(QueryString.parse(document.search), "order");
    setHotels(selectedHotels);

    setReplaceHotelId({ replaceHotelId: hotel_id, replaceProductItemId: pi_id });
    push({ pathname: SMART_PACKAGE_SELECT_HOTELS, search: newSearch });
  };

  const getRoundedTotalPrice = () => {
    if (isCusomerPAC) {
      return Math.ceil(Number(price.tint_charge_price));
    }

    if (isUserPAC) {
      return Math.ceil(Number(price.rounded_taxed_components_price));
    }

    return roundedTotalPrice;
  };

  return (
    <OrderSummaryView
      lineItems={hideLineItems ? [] : lineItems}
      showTotal={!isCheckout}
      name={displayName}
      citizenshipTaxAmount={price.rounded_citizenship_tax_amount}
      startLocation={startLocation}
      {...(startLocation && endLocation?.date && { endLocation })}
      roundedTotalPrice={getRoundedTotalPrice()}
      requestedPrice={requestedPrice}
      totalPrice={totalPrice}
      rooms={rooms}
      showSummaryDetails={!isCustomPrice && !isCusomerPAC}
      {...(hints?.length && { hints })}
      currency={currency}
      agentFeeAmount={showAgentCommission ? price?.agent_fee_amount : 0}
      agentPaysAmount={showAgentPays ? price?.tint_charge_price : 0}
      breakfastIncluded={!isCheckout && breakfastIncluded}
      accommodationJsx={
        isCustomer && autoAccommodation ? (
          <HotelsItinerary
            useMdLayout={false}
            hotels={hotels}
            onReplace={onReplaceHotels}
            missedBuckets={missedBuckets}
            hotelLevel={orderData.hotel_level}
          />
        ) : null
      }
      onQuoteClick={toggleQuoteModal}
    />
  );
};

export default SPOrderSummary;
