import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { parseClasses, setQuotePackageSubmitted, validationRule } from "@tint_fe/helpers";
import { useBreakPoints, useReduxAction, useTranslation } from "../../hooks";
import { toggleRequestQuoteModal } from "../../redux/global/globalActions";
import { ArrowRight, Button, ControlledInput, Cross, Modal } from "@tint_fe/ui";
import { useForm } from "react-hook-form";
import { postSaveProposal } from "../../api";
import { updateOrderCustomerLocaly } from "../../redux/actions";

const RequestQuoteModal = () => {
  const toggleModal = useReduxAction(toggleRequestQuoteModal);
  const updateCustomer = useReduxAction(updateOrderCustomerLocaly);

  const { t } = useTranslation();
  const { isSm } = useBreakPoints();
  const { mainCls, elCls } = parseClasses({ base: "request-quote" });

  const { control, handleSubmit, setValue } = useForm();

  const rootId = useSelector(({ global }) => global.rootId);
  const openModal = useSelector(({ global }) => global.openRequestQuoteModal);
  const termsAndConditionLink = useSelector(({ global }) => global.integration.terms_and_conditions_url);

  const orderNumber = useSelector(({ order }) => order?.data?.number);
  const orderCustomer = useSelector(({ order }) => order?.data?.customer);

  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const closeModal = () => {
    toggleModal(false);
    setTimeout(() => {
      setIsSubmitted(false);
    }, 300);
  };

  useEffect(() => {
    if (orderCustomer?.email && openModal) {
      setValue("email", orderCustomer.email);
      setValue("first_name", orderCustomer.first_name);
      setValue("last_name", orderCustomer.last_name);
    }
  }, [orderCustomer, openModal]);

  const submitForm = handleSubmit((val) => {
    postSaveProposal({ body: { order: { ...val, number: orderNumber } } });
    updateCustomer(val);
    setQuotePackageSubmitted(orderNumber);
    setIsSubmitted(true);
  });

  if (isSubmitted) {
    return (
      <Modal closeModal={closeModal} containerClass={mainCls} isOpen={openModal} rootId={rootId}>
        <div className={elCls("close-btn-wrapper")}>
          <div className={elCls("close-btn")} onClick={closeModal}>
            <Cross />
          </div>
        </div>
        {isSm ? (
          <h3 className={elCls("titlee", "second-font")}>
            Your request has
            <br />
            been submitted!
          </h3>
        ) : (
          <h3 className={elCls("titlee", "second-font")}>
            Your request has been
            <br />
            submitted!
          </h3>
        )}

        <p className={elCls("success-text")}>
          {"You'll receive your tailored package proposal via email soon, so keep an eye on your inbox."}
        </p>
      </Modal>
    );
  }

  return (
    <Modal closeModal={closeModal} containerClass={mainCls} isOpen={openModal} rootId={rootId}>
      <div className={elCls("close-btn-wrapper")}>
        <div className={elCls("close-btn")} onClick={closeModal}>
          <Cross />
        </div>
      </div>
      {isSm ? (
        <h3 className={elCls("titlee", "second-font")}>
          Save booking and
          <br />
          send the quote by
          <br />
          email
        </h3>
      ) : (
        <h3 className={elCls("titlee", "second-font")}>
          Save booking and send the
          <br />
          quote by email
        </h3>
      )}

      <div className={elCls("inputs-row")}>
        <ControlledInput
          control={control}
          className={elCls("input")}
          name="first_name"
          placeholder={"Your First Name"}
          label={"First Name*"}
          removeStartEndSpaces
          rules={{ ...validationRule.required("First Name") }}
        />
        <ControlledInput
          control={control}
          className={elCls("input")}
          name="last_name"
          placeholder={"Your Last Name"}
          label={"Last Name*"}
          removeStartEndSpaces
          rules={{ ...validationRule.required("Last Name") }}
        />
      </div>
      <ControlledInput
        control={control}
        className={elCls("input")}
        name="email"
        placeholder={"example@gmail.com"}
        label={"Email Address*"}
        rules={{
          ...validationRule.required(t("formField.emailAddress")),
          ...validationRule.email,
        }}
      />
      <Button small background="dark" onClick={submitForm} className={elCls("btn")} nextIcon={<ArrowRight />}>
        {"Send"}
      </Button>
      <span className={elCls("accept-terms")}>
        {"By pressing Send, I agree to the "}
        <a
          href={termsAndConditionLink}
          className="customer-form__accept-terms-link request-quote__underlined"
          target="_blank"
          rel="noreferrer"
        >
          {"Terms & Conditions and Privacy Policy"}
        </a>
        {"."}
      </span>
    </Modal>
  );
};

RequestQuoteModal.propTypes = {};

export default RequestQuoteModal;
