import React from "react";
import { useSelector } from "react-redux";

import { PaymentSummary as SharedPaymentSummary } from "@tint_fe/ui";
import { dates } from "@tint_fe/helpers";
import { useAgentCommission, useRouterMatch, useTranslation } from "@tint_fe/hooks";

import { useActivitySummaryInfo, useDiscountInfo, useSendDiscount } from "../../../hooks";
import { bookingTypes } from "../../../helpers/constants";
import { useCheckIfBreakfastIncluded, useSPSummaryInfo } from "../../containers/SmartPackage/hooks";
import useTourSummaryInfo from "../../containers/Tour/hooks/useTourSummaryInfo";
import useHotelSummaryInfo from "../../containers/Hotel/hooks/useHotelSummaryInfo";
import useBundleSummary from "../../containers/Bundle/useBundleSummary";
import { SMART_PACKAGE } from "../../router/routes";
import { selectIfCustomPrice } from "../../../redux/sharedSelectors";
import PolicyBtn from "../PolicyBtnModal/PolicyBtn";

const PaymentSummary = () => {
  const sendDiscountCode = useSendDiscount();

  const orderData = useSelector(({ order }) => order.data);
  const { showAgentCommission, showAgentPays } = useAgentCommission({ order: orderData });

  const product = useSelector((state) => state.product.data);
  const { descriptions = {} } = product;
  const bookingType = useSelector(({ global, order }) => order.data?.build_type || global.bookingType);
  const price = useSelector(({ global }) => global.price);
  const isCustomPrice = useSelector(selectIfCustomPrice);
  const roundedTaxAmount = price?.product_items && price?.product_items[0]?.rounded_citizenship_tax_amount;
  const lateBookingSupplement = price?.product_items && price?.product_items[0]?.rounded_late_booking_supplement;
  const citizenshipTaxAmount = Math.ceil(
    Number(roundedTaxAmount || price.citizenship_tax_amount || price.rounded_citizenship_tax_amount),
  );

  const startDate = useSelector(({ order }) => order.data.package_start_date);
  const requestedPrice = useSelector(({ order }) => order.dataForPayment.price_to_be_charged);

  const { lineItems: SPlineItems = [], rooms, requestedPrice: SPRequestedPrice } = useSPSummaryInfo();
  const tourLineItems = useTourSummaryInfo(price);
  const activityLineItems = useActivitySummaryInfo(price);
  const { lineItems: hotelLineItems = [] } = useHotelSummaryInfo();
  const { showDiscount, discountOpenByDefault, discountPercent, discountAmount } = useDiscountInfo();
  const { lineItems: bundleLineItems = [] } = useBundleSummary();
  const { t } = useTranslation();
  const isSmartPackage = useRouterMatch(SMART_PACKAGE);
  const checkIfBreakfastIncluded = useCheckIfBreakfastIncluded();
  const breakfastIncluded = checkIfBreakfastIncluded();

  const showRefoundPolicyWarn = startDate && dates.getNumberOfDaysFromToday(startDate) <= 30;
  const hints = [showRefoundPolicyWarn && isSmartPackage && t("refund.policy")].filter(Boolean);

  const getLineItems = () => {
    switch (bookingType) {
      case bookingTypes.SMART_PACKAGE:
      case bookingTypes.CUSTOM_PACKAGE:
        return SPlineItems;
      case bookingTypes.HOTEL:
        return hotelLineItems;
      case bookingTypes.TOUR:
        return tourLineItems;
      case bookingTypes.BUNDLE:
        return bundleLineItems;
      case bookingTypes.ACTIVITY:
        return activityLineItems;
      default:
        return [];
    }
  };

  const computedLineItems = getLineItems();

  citizenshipTaxAmount &&
    computedLineItems.push({
      name: "Included Israel VAT",
      price: citizenshipTaxAmount,
      hideNumber: true,
    });

  return (
    <SharedPaymentSummary
      lineItems={computedLineItems}
      showDiscount={showDiscount}
      discountOpenByDefault={discountOpenByDefault}
      percent={discountPercent}
      discountAmount={discountAmount}
      sendDiscountCode={sendDiscountCode}
      hint={hints}
      rooms={rooms}
      lateBookingSupplement={lateBookingSupplement}
      partialPrice={price?.partial?.partial_price}
      splitPrice={price?.split?.split_price}
      isCustomPrice={isCustomPrice}
      totalPrice={price?.tint_charge_price}
      roundedTotalPrice={price.tint_charge_price}
      requestedPrice={requestedPrice || SPRequestedPrice}
      descriptions={descriptions}
      agentFeeAmount={showAgentCommission ? price?.agent_fee_amount : 0}
      agentPaysAmount={showAgentPays ? price?.tint_charge_price : 0}
      descriptionsComponent={<PolicyBtn />}
      breakfastIncluded={breakfastIncluded}
    />
  );
};

export default PaymentSummary;
