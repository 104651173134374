export const validationRule = {
  required: (fieldName) => ({
    required: {
      value: true,
      message: { text: "error.required", fieldName },
    },
  }),
  isRequired: () => ({
    required: {
      value: true,
      message: { text: "Required field" },
    },
  }),
  email: {
    pattern: {
      value: /^(\s?)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}(\s?)$/i,
      message: { text: "error.email.message" },
    },
  },
  name: (fieldName) => ({
    pattern: {
      value: /^[^-\s][^0-9!@#$%^&*)(+=_<>}{«]+$/g,
      message: { text: "error.validation.general", fieldName },
    },
  }),
  minLength: (fieldName, length) => ({
    minLength: {
      value: length,
      message: { text: "error.validation.general", fieldName },
    },
  }),

  validatePhoneCountryCode: (isRecognized) => ({
    validate: {
      isCodeRecognized: () => isRecognized || "error.phone.valid",
    },
  }),

  validateGoogleAutocompleteRequired: () => {
    return (val) => {
      return val.name ? null : "error.required";
    };
  },

  zeroOrPositive: (fieldName) => ({
    min: {
      value: 0,
      message: { text: "error.validation.general", fieldName },
    },
  }),
};

// use ths function when need to use custom validation logic
export const normalizedValidateError = (error, label) => {
  return error?.message && typeof error?.message === "string"
    ? { text: error.message, fieldName: label }
    : error?.message;
};
