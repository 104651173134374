import React, { useState } from "react";
import T from "prop-types";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { parseClasses, dates, checkCurrencyChar, bookingTypes, isPriceInSmallRange } from "@tint_fe/helpers";
import { useGetUser, useIsRequestPayment, useTranslation } from "@tint_fe/hooks";

import { Calendar } from "../../assets/icons";
import { Loader, Img, DiscountCode } from "..";
import SummaryDetailsCollapse from "./SummaryDetailsCollapse";
import SummaryHint from "./SummaryHint";
import SummaryTaxes from "./SummaryTaxes";
import SummaryRequestQuote from "./SummaryRequestQuote";

const formatDate = (date, bookingType) => {
  return (
    date &&
    dates.format(date, "EEEE, LLLL dd, yyyy", ![bookingTypes.SMART_PACKAGE, bookingTypes.HOTEL].includes(bookingType))
  );
};

const formatTime = (date) => {
  return date && dates.format(date, "HH:mm", true);
};

const hideOrderDetailsBookingTypes = [bookingTypes.TOUR, bookingTypes.SMART_PACKAGE, bookingTypes.CUSTOM_PACKAGE];

const SharedSummary = ({
  summaryTitle,
  img,
  hints,
  name,
  tours,
  rooms,
  hotels,
  loading,
  lineItems,
  lateBookingSupplement,
  totalPrice,
  endLocation,
  showDiscount,
  discountOpenByDefault,
  startLocation,
  ticketOption,
  requestedPrice,
  partialPrice,
  splitPrice,
  discountPercent,
  discountAmount,
  sendDiscountCode,
  citizenshipTaxAmount,
  discountCode,
  roundedTotalPrice,
  showSummaryDetails,
  currency,
  taxes,
  descriptions,
  descriptionsComponent,
  agentFeeAmount,
  agentPaysAmount,
  breakfastIncluded,
  accommodationJsx,
  onQuoteClick,
}) => {
  const [sendingDiscount, setSendingDiscount] = useState();
  const currencyChar = checkCurrencyChar(currency);
  const { user } = useGetUser();
  const { pathname } = useLocation();
  const showRequestedPriceAgent = user?.isAgent ? !!partialPrice : true;
  const bookingType = useSelector(({ global, order }) => order.data?.build_type || global.bookingType);
  const orderLoading = useSelector(({ order }) => order.loading);
  const orderNumber = useSelector(({ order }) => order.data?.number);
  const hasAccommodationJsx = !isEmpty(accommodationJsx);

  const { t, isRtl } = useTranslation();

  const { mainCls, elCls } = parseClasses({ base: "summary", classes: [isRtl && "rtl"] });

  const showRequestedPrice =
    (typeof roundedTotalPrice !== "undefined" &&
      typeof requestedPrice !== "undefined" &&
      !sendingDiscount &&
      Number(requestedPrice) !== Number(totalPrice) &&
      !orderLoading) ||
    partialPrice ||
    splitPrice;
  const toBePaidPrice = Math.ceil(Number(partialPrice || splitPrice || requestedPrice));
  const sumBeforeDicount = Math.round(Number(roundedTotalPrice) + Number(discountAmount));

  const isRequestPayment = useIsRequestPayment();
  const hideOrderDetails =
    hideOrderDetailsBookingTypes.includes(bookingType) &&
    isRequestPayment &&
    !isPriceInSmallRange(roundedTotalPrice, toBePaidPrice);
  const hideTotalPriceBundle =
    bookingType === bookingTypes.BUNDLE && isRequestPayment && !isPriceInSmallRange(totalPrice, toBePaidPrice);

  const showDescriptions = !isEmpty(descriptions) && bookingType === bookingTypes.HOTEL;
  const showQuote = bookingType === bookingTypes.SMART_PACKAGE && pathname.includes("customer");

  return (
    <div className={mainCls}>
      <div className={elCls("container-padding")}>
        <div className={elCls("title", "uppercase bold mg-b-4")}>{t(summaryTitle)}</div>

        {/* name */}
        <div className={elCls("name")}>
          <div className="p5 bold">{name}</div>
          {img && <Img src={img} alt="Product image" className="mg-0 mg-l-2" />}
        </div>

        {loading ? (
          <Loader />
        ) : (
          <>
            {/*  start end locations */}
            {(!isEmpty(startLocation) || !isEmpty(endLocation)) && (
              <div className={hideOrderDetails ? "mg-t-2" : elCls("dates", "mg-t-2")}>
                <div className={elCls("title", "uppercase bold mg-b-2")}>{"Dates & Locations"}</div>
                {!isEmpty(ticketOption) && (
                  <div className={elCls("date", "summary__date", "mg-b-2")}>
                    {ticketOption.ticketName ? `Ticket option: ${ticketOption.ticketName}` : ""}
                  </div>
                )}
                <div className={elCls("date", "mg-b-2")}>
                  <Calendar className={elCls("calendar-icon", "mg-r-2")} />
                  {t("Start")}: <b>{formatDate(startLocation.date, bookingType)} </b>
                  {startLocation.name ? `${t("from")} ${startLocation.name}` : ""}
                  {startLocation.time ? ` ${t("at")} ${formatTime(startLocation.time)}` : ""}
                  {startLocation.stringTime ? ` ${t("at")} ${startLocation.stringTime}` : ""}
                </div>

                {!isEmpty(endLocation) && (
                  <div className={elCls("date", "summary__date__with-connect", "mg-b-2")}>
                    <Calendar className={elCls("calendar-icon", "mg-r-2")} />
                    {t("End")}: <b>{formatDate(endLocation.date, bookingType)} </b>
                    {endLocation.name ? `${t("in")} ${endLocation.name}` : ""}
                  </div>
                )}
              </div>
            )}

            {hasAccommodationJsx && accommodationJsx}

            {/*  product items */}
            {showSummaryDetails && !hideOrderDetails && (
              <SummaryDetailsCollapse
                discountClassName={elCls("discount--mobile")}
                className={elCls("collapse")}
                lineItems={lineItems}
                lineItemsClassName={elCls("item", "mg-b-1")}
                hotels={hotels}
                hotelsClassName={elCls("item", "additional", "mg-t-1")}
                tours={tours}
                toursClassName={elCls("item", "additional", "mg-t-1")}
                rooms={rooms}
                roomsClassName={elCls("item", "additional", "mg-t-1")}
                lateBookingSupplement={lateBookingSupplement}
                lateBookingSupplementClassName={elCls("item", "mg-t-1")}
                showDiscount={showDiscount}
                percent={discountPercent}
                discountCode={discountCode}
                sendDiscountCode={sendDiscountCode}
                currency={currency}
                breakfastIncluded={breakfastIncluded}
                discountOpenByDefault={discountOpenByDefault}
              />
            )}

            {!!roundedTotalPrice && !hideOrderDetails && !hideTotalPriceBundle && (
              <div className={elCls("total-sum", "bold uppercase mg-t-2")}>
                <span>{t("summary.totalCharge")}</span>
                <div>
                  {!!Number(discountPercent) && showDiscount && (
                    <span className={elCls("total-sum-before-discount")}>
                      {currencyChar}
                      {sumBeforeDicount}
                    </span>
                  )}
                  <span>
                    {currencyChar}
                    {roundedTotalPrice}
                  </span>
                </div>
              </div>
            )}

            {showRequestedPrice && showRequestedPriceAgent && (
              <div className={elCls("total-sum", "bold uppercase mg-t-4")}>
                <span>{t("summary.toBePaid")}</span>
                <span>
                  {currencyChar}
                  {toBePaidPrice}
                </span>
              </div>
            )}

            {citizenshipTaxAmount > 0 && (
              <span className={elCls("citizenship-tax", "additional", "mg-t-2")}>
                <span>Included Israel VAT</span>
                <span className="text">
                  {currencyChar}
                  {Math.ceil(Number(citizenshipTaxAmount))}
                </span>
              </span>
            )}

            {!isEmpty(taxes) && (taxes.included_taxes || taxes.excluded_taxes) && (
              <SummaryTaxes
                className={elCls("taxes", showDiscount && "taxes-b-b", "mg-t-2")}
                taxes={taxes}
                currencyChar={currencyChar}
              />
            )}

            {!!Number(agentFeeAmount) && (
              <div className={elCls("agent-fee-container")}>
                {!!Number(agentFeeAmount) && (
                  <div className={elCls("agent-fee", "bold uppercase mg-t-2  mg-b-2")}>
                    <span>{t("summary.agentFeeAmount")}</span>
                    <span>
                      {currencyChar}
                      {Number(agentFeeAmount).toFixed(2)}
                    </span>
                  </div>
                )}
                {!!Number(agentPaysAmount) && (
                  <div className={elCls("agent-fee", "bold uppercase mg-b-2")}>
                    <span>{t("summary.agentPays")}</span>
                    <span>
                      {currencyChar}
                      {Number(agentPaysAmount).toFixed(2)}
                    </span>
                  </div>
                )}
              </div>
            )}

            {showDiscount && (
              <DiscountCode
                className={elCls("discount--desktop")}
                percent={discountPercent}
                discountCode={discountCode}
                openByDefault={discountOpenByDefault}
                sendDiscountCode={sendDiscountCode}
                setSendingDiscount={setSendingDiscount}
              />
            )}

            <SummaryHint hint={hints} className={elCls("hint", "mg-t-5")} />
            {!!showDescriptions && descriptionsComponent}
          </>
        )}
      </div>
      {showQuote && <SummaryRequestQuote onClick={onQuoteClick} orderNumber={orderNumber} />}
    </div>
  );
};

SharedSummary.defaultProps = {
  summaryTitle: "summary.title",
  hint: "",
  name: "",
  startLocation: {},
  endLocation: {},
  priceItems: [],
  hotels: [],
  tours: [],
  showSummaryDetails: true,
  currency: "USD",
  taxes: {},
  accommodationJsx: null,
  onQuoteClick: () => null,
};

SharedSummary.propTypes = {
  summaryTitle: T.string,
  img: T.string,
  roundedTotalPrice: T.oneOfType([T.number, T.string]),
  hints: T.oneOfType([T.string, T.arrayOf(T.string)]),
  name: T.string,
  isTour: T.bool,
  startLocation: T.shape({
    date: T.oneOfType([T.string, T.instanceOf(Date)]),
    name: T.string,
    time: T.oneOfType([T.string, T.instanceOf(Date)]),
    stringTime: T.string,
  }),
  ticketOption: T.shape({
    ticketName: T.string,
  }),
  endLocation: T.shape({
    date: T.oneOfType([T.string, T.instanceOf(Date)]),
    name: T.string,
  }),
  priceItems: T.arrayOf(
    T.shape({
      name: T.string,
      number: T.number,
      pricePerItem: T.number,
    }),
  ),
  hotels: T.arrayOf(
    T.shape({
      name: T.string,
      days: T.number,
    }),
  ),
  tours: T.arrayOf(
    T.shape({
      name: T.string,
      number: T.number,
    }),
  ),
  lineItems: T.array,
  lateBookingSupplement: T.number,
  citizenshipTaxAmount: T.oneOfType([T.string, T.number]),
  loading: T.bool,
  requestedPrice: T.string,
  showDiscount: T.bool,
  discountOpenByDefault: T.bool,
  discountPercent: T.oneOfType([T.string, T.number]),
  discountAmount: T.number,
  sendDiscountCode: T.func,
  discountCode: T.string,
  partialPrice: T.number,
  splitPrice: T.number,
  rooms: T.array,
  totalPrice: T.oneOfType([T.string, T.number]),
  showSummaryDetails: T.bool,
  currency: T.string,
  taxes: T.shape({
    included_taxes: T.number,
    excluded_taxes: T.number,
  }),
  descriptions: T.object,
  descriptionsComponent: T.node,
  agentFeeAmount: T.oneOfType([T.string, T.number]),
  agentPaysAmount: T.oneOfType([T.string, T.number]),
  breakfastIncluded: T.bool,
  accommodationJsx: T.node,
  onQuoteClick: T.func,
};

export default SharedSummary;
