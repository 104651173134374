import React from "react";
import { useSelector } from "react-redux";
import T from "prop-types";
import { isEmpty } from "lodash";

import { parseClasses } from "@tint_fe/helpers";
import { AlternativesIcon, Hotel, Info4 } from "@tint_fe/ui";
import { CheckMark, Info16 } from "../../../../assets/icons";
import BookingHotelInfoModal from "../../../components/BookingHotelInfoModal";
import { ga_change_hotel_click } from "@tint_fe/helpers/services/google-analytics-v2";

const formatHotelLevelTitle = (level) => {
  switch (level) {
    case "Budget":
      return "Budget";
    case "Capsule":
      return "Capsule Hotel";
    case "Level 1":
      return "Standard";
    case "Level 2":
      return "Superior";
    case "Level 3":
      return "Luxury";
    default:
      return "Superior";
  }
};

const HotelsItinerary = ({ useMdLayout, hotels, onReplace, missedBuckets, hotelLevel }) => {
  const { elCls } = parseClasses({ base: "customer-form-sp" });
  const [showMoreInfo, setShowMoreInfo] = React.useState({});
  const spData = useSelector((state) => state.smartPackage.data) || {};
  const isOrderLoading = useSelector((state) => state.order.loading);
  const isFetchPricesLoading = useSelector((state) => state.global.loadingFetchPrices);
  const anyLoading = isOrderLoading || isFetchPricesLoading;
  const hotelLevelTitle = formatHotelLevelTitle(hotelLevel);

  const showMoreInfoModal = (hotel) => {
    setShowMoreInfo({
      ...hotel,
    });
  };

  const closeMoreInfoModal = () => {
    setShowMoreInfo({});
  };

  return (
    <div className={elCls(useMdLayout ? "wrapper" : "wrapper-lg")}>
      <span className={elCls("accommodation-title")}>{"ACCOMMODATION"}</span>
      <span className={elCls("subtitle")}>
        {
          "We have included the following hotels based on your selected level. You can see more details and alternatives."
        }
      </span>
      {hotels.map((hotel, index) => {
        const breakfastIncluded = hotel?.hotel_line_items?.[0]?.meal_plan === "Breakfast is included";
        const isLast = index === hotels.length - 1;

        return (
          <div className={elCls("card-container", isLast ? "mg-b-0" : "mg-b-4")} key={hotel.product_id}>
            <div className={elCls("icon-wrapper")}>
              <Hotel color={"#f5854d"} />
            </div>
            <div className={elCls("content-container")}>
              <span className={elCls("city-title")}>
                {`${spData?.cities?.find((city) => city.id === hotel.city_id)?.name}: `}
                {anyLoading ? (
                  <span className={elCls("loader")} />
                ) : (
                  <span className={elCls("hotel-title")}>{hotel.display_name}</span>
                )}
              </span>
              {breakfastIncluded && (
                <div className={"mg-t-2"}>
                  <CheckMark />
                  <span className={elCls("breakfast-title")}>{"Breakfast is included"}</span>
                </div>
              )}
              <div className={elCls("row", "mg-t-2")}>
                <div onClick={() => showMoreInfoModal(hotel)} className={elCls("row-pointer")}>
                  <Info16 />
                  <span className={elCls("info-title")}>{"Info"}</span>
                </div>
                <div
                  onClick={() => {
                    ga_change_hotel_click();
                    onReplace({ hotel_id: hotel.product_id, pi_id: hotel.id });
                  }}
                  className={elCls("row-pointer")}
                >
                  <AlternativesIcon />
                  <span className={elCls("info-title")}>{"Change hotel"}</span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {missedBuckets?.length > 0 && (
        <span className={elCls("missed-buckets")}>
          <div>
            <Info4 />
          </div>
          <span className={elCls("missed-buckets-title")}>
            {`Just so you know, ${hotelLevelTitle} hotels are limited in ${missedBuckets.join(
              ", ",
            )}. We have included hotels from the closest available level.`}
          </span>
        </span>
      )}
      <BookingHotelInfoModal
        product_id={showMoreInfo.product_id}
        isOpen={!isEmpty(showMoreInfo)}
        isMapOpen={false}
        closeModal={closeMoreInfoModal}
        showButtons={false}
        existingHotelInfo={showMoreInfo}
      />
    </div>
  );
};

HotelsItinerary.defaultProps = {
  useMdLayout: true,
  missedBuckets: [],
  hotelLevel: "Level 1",
};

HotelsItinerary.propTypes = {
  hotels: T.array,
  onReplace: T.func,
  useMdLayout: T.bool,
  missedBuckets: T.array,
  hotelLevel: T.string,
};

export default HotelsItinerary;
