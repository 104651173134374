import React from "react";
import T from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import { ROOMS_FIELD_MAX_VALUE } from "../../../../helpers/constants";
import { useTranslation } from "@tint_fe/hooks";
import { Bedtime, CountingField, ImportantNote, TintProsCheckMark } from "@tint_fe/ui";
import { checkCurrencyChar, parseClasses } from "@tint_fe/helpers";

const getTotalHotelItemSelected = (hotelItems) => {
  return hotelItems ? Object.values(hotelItems).reduce((acc, el) => acc + (el?.quantity || 0), 0) : 0;
};

const HotelRoomItem = ({
  booking_code,
  cancellation,
  capacity,
  loading,
  meal_plan,
  price,
  strikehold_price,
  rate_type,
  // price_per_night,
  id,
  currency,
  included_taxes,
  excluded_taxes,
  bed_type,
}) => {
  const { t } = useTranslation();
  const { elCls } = parseClasses({
    base: "room-item",
  });
  const { control, watch } = useFormContext();
  const maxRooms = watch("rooms")?.length || 0;
  const totalHotelItemSelected = getTotalHotelItemSelected(watch("hotel_line_items_attributes"));
  const currencyChar = checkCurrencyChar(currency);
  const isRoomOnly = meal_plan === "Room Only";
  const nonRefundable = cancellation?.includes("non-refundable");

  return (
    <div className={elCls("content")}>
      <div className={elCls("content-description")}>
        <div className={elCls("row")}>
          {nonRefundable && <ImportantNote className={elCls("icon-note")} />}
          <p className={elCls("paragraph-bold")}>
            {nonRefundable ? (
              cancellation
            ) : (
              <>
                <span className={elCls("cancellation")}>{"Free cancellation"}</span>
                {cancellation.replace("Free cancellation", "")}
              </>
            )}
          </p>
        </div>

        <div className={elCls("row")}>
          {isRoomOnly ? (
            <ImportantNote className={elCls("icon-note")} />
          ) : (
            <TintProsCheckMark className={elCls("icon-checkmark")} />
          )}
          <p className={elCls("paragraph")}>{meal_plan}</p>
        </div>
        {!!bed_type && (
          <div className={elCls("row")}>
            <Bedtime />
            <p className={elCls("paragraph")}>{bed_type}</p>
          </div>
        )}

        <p className={elCls("paragraph")}>
          {t("sleepsFor")} {capacity}
        </p>

        <div className={elCls("content-price")}>
          <span className={elCls("total-price-text")}>{t("summary.totalCharge")}:</span>
          <div className={elCls("price-row")}>
            <p className={elCls("price-text")}>
              {currencyChar}
              {price}
            </p>
            {!!strikehold_price && (
              <p className={elCls("strikehold-price-text")}>
                {currencyChar}
                {strikehold_price}
              </p>
            )}
          </div>
          {rate_type === "MOB" && <span className={elCls("total-price-text")}>{"Mobile-only price"}</span>}
          {rate_type === "MO" && <span className={elCls("total-price-text")}>{"Members' Price"}</span>}
          {/* <div>
            {currencyChar}
            {price_per_night}&nbsp;/&nbsp;{t("night")}
          </div> */}
        </div>

        {(included_taxes || excluded_taxes || true) && (
          <>
            {included_taxes && (
              <>
                <span>
                  {t("formField.includedTaxes", { currency: currencyChar, value: Number(included_taxes).toFixed(2) })}
                </span>
              </>
            )}
            {excluded_taxes && (
              <span>
                <br />
                {t("formField.excludedTaxes", { currency: currencyChar, value: Number(excluded_taxes).toFixed(2) })}
              </span>
            )}
          </>
        )}
      </div>
      <div className={elCls("controls")}>
        <Controller
          control={control}
          name={`hotel_line_items_attributes.${id}`}
          defaultValue={{
            booking_code,
            quantity: 0,
          }}
          // eslint-disable-next-line no-unused-vars
          render={({ field: { ref, onChange, value, ...retField } }) => {
            return (
              <CountingField
                type="range"
                incrementDisabled={maxRooms <= totalHotelItemSelected}
                max={ROOMS_FIELD_MAX_VALUE}
                loading={loading}
                className={elCls("numeric-input")}
                label="Select rooms:"
                onChange={(val) => {
                  onChange({
                    booking_code,
                    quantity: val,
                  });
                }}
                value={value.quantity}
                useLegacyIcons={false}
                {...retField}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

HotelRoomItem.propTypes = {
  booking_code: T.string,
  cancellation: T.string,
  capacity: T.string,
  loading: T.bool,
  meal_plan: T.string,
  price: T.number,
  strikehold_price: T.number,
  rate_type: T.string,
  price_per_night: T.number,
  quantity: T.number,
  id: T.string,
  currency: T.string,
  included_taxes: T.oneOfType([T.number, T.string]),
  excluded_taxes: T.oneOfType([T.number, T.string]),
  bed_type: T.string,
};

export default HotelRoomItem;
