import React from "react";
import T from "prop-types";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import { bookingTypes, parseClasses } from "@tint_fe/helpers";

import SummaryDetailsCollapse from "./SummaryDetailsCollapse";
import SummaryHint from "./SummaryHint";

const PaymentSummary = ({ isRtl, className, hint, descriptions, descriptionsComponent, ...rest }) => {
  const { mainCls, elCls } = parseClasses({
    base: "summary",
    classes: [isRtl && "rtl"],
    additional: ["summary-separate-payment", className],
  });
  const bookingType = useSelector(({ global, order }) => order.data?.build_type || global.bookingType);
  const showDescriptions = !isEmpty(descriptions) && bookingType === bookingTypes.HOTEL;

  return (
    <div className={mainCls}>
      <SummaryDetailsCollapse
        openLabel="Hide Order Summary"
        closeLabel="Show Order Summary"
        className={elCls("collapse")}
        lineItemsClassName={elCls("item", "mg-b-1")}
        lateBookingSupplementClassName={elCls("item", "mg-b-1")}
        hotelsClassName={elCls("item", "additional", "mg-t-1")}
        toursClassName={elCls("item", "additional", "mg-t-1")}
        roomsClassName={elCls("item", "additional", "mg-t-1")}
        {...rest}
      />
      <SummaryHint hint={hint} small className={elCls("hint", "mg-t-2")} />
      {!!showDescriptions && descriptionsComponent}
    </div>
  );
};

PaymentSummary.propTypes = {
  isRtl: T.bool,
  className: T.string,
  hint: T.oneOfType([T.string, T.array]),
  descriptions: T.object,
  descriptionsComponent: T.node,
};

export default PaymentSummary;
