const _checkStringsArray = (arr, str) => {
  if (Array.isArray(arr)) {
    return !!arr?.find((el) => typeof el === "string" && el.includes(str));
  }

  return false;
};

const _checkIsRatesIsReason = ({ messages, fields }) => {
  return _checkStringsArray(messages, "rate is not available anymore") || _checkStringsArray(fields, "rooms");
};

//  This function will check error on creating order,
//  and if reason will be -> "rate is not available anymore",
//  this function will try again to create order

let count = 0;
function* checkCreatingOrderError({ err, tryAgain, onError }) {
  const isRatesWrong = _checkIsRatesIsReason(err);

  if (count < 1 && isRatesWrong) {
    count = 1;
    yield tryAgain();
  }
  count = 0;
  yield onError();
}

export default checkCreatingOrderError;
