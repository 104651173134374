import * as React from "react";
const ImportantNote = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99984 1.99984C4.68613 1.99984 1.99984 4.68613 1.99984 7.99984C1.99984 11.3135 4.68613 13.9998 7.99984 13.9998C11.3135 13.9998 13.9998 11.3135 13.9998 7.99984C13.9998 4.68613 11.3135 1.99984 7.99984 1.99984ZM0.666504 7.99984C0.666504 3.94975 3.94975 0.666504 7.99984 0.666504C12.0499 0.666504 15.3332 3.94975 15.3332 7.99984C15.3332 12.0499 12.0499 15.3332 7.99984 15.3332C3.94975 15.3332 0.666504 12.0499 0.666504 7.99984ZM7.99984 4.6665C8.36803 4.6665 8.6665 4.96498 8.6665 5.33317V7.99984C8.6665 8.36803 8.36803 8.6665 7.99984 8.6665C7.63165 8.6665 7.33317 8.36803 7.33317 7.99984V5.33317C7.33317 4.96498 7.63165 4.6665 7.99984 4.6665ZM7.33317 10.6665C7.33317 10.2983 7.63165 9.99984 7.99984 9.99984H8.0065C8.37469 9.99984 8.67317 10.2983 8.67317 10.6665C8.67317 11.0347 8.37469 11.3332 8.0065 11.3332H7.99984C7.63165 11.3332 7.33317 11.0347 7.33317 10.6665Z"
      fill="#F38E3D"
    />
  </svg>
);
export default ImportantNote;
