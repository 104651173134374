export * from "@tint_fe/redux-utils/src/global/globalActionTypes";

export const FETCH_PRICES_REQUEST = "FETCH_PRICES_REQUEST";
export const FETCH_PRICES_SUCCESS = "FETCH_PRICES_SUCCESS";
export const FETCH_PRICES_FAILED = "FETCH_PRICES_FAILED";

export const SET_LOADING_ACTION = "SET_LOADING_ACTION";

export const SET_ERROR_ACTION = "SET_ERROR_ACTION";

export const SET_DROP_OFF_LOCATION_ACTION = "SET_DROP_OFF_LOCATION_ACTION";

export const SET_STEP_ACTION = "SET_STEP_ACTION";

export const SET_BOOKING_TYPE = "SET_BOOKING_TYPE";

export const UPDATE_RATES_REQUEST = "UPDATE_RATES_REQUEST";
export const UPDATE_RATES_PROCESSING = "UPDATE_RATES_PROCESSING";
export const UPDATE_RATES_SUCCESS = "UPDATE_RATES_SUCCESS";
export const UPDATE_RATES_FAILED = "UPDATE_RATES_FAILED";

export const SAVE_ROOT_ID = "SAVE_ROOT_ID";

export const SAVE_SELECTED_PAYMENT_METHOD = "SAVE_SELECTED_PAYMENT_METHOD";
export const SET_REFETCH_PRICES_DUE_CURRENCY_CHANGE = "SET_REFETCH_PRICES_DUE_CURRENCY_CHANGE";
export const SET_SHOULD_PRESELECT_SPLIT_PAYMENT = "SET_SHOULD_PRESELECT_SPLIT_PAYMENT";

export const TOGGLE_REQUEST_QUOTE_MODAL = "TOGGLE_REQUEST_QUOTE_MODAL";
