import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { findLast } from "lodash";

import { useRouterMatch, useTranslation } from "@tint_fe/hooks";
import { dates } from "@tint_fe/helpers";

import { SPCheckoutFormFields } from "../helpers";
import { CHECKOUT, SELECT_HOTELS } from "../../../router/routes";
import { bookingTypes } from "../../../../helpers/constants";
import { useSPSelectHotelsPrice } from "./useSPSelectHotelsPrice";
import { useSPOrderPrice } from "./useSPOrderPrice";

const { START_DATE, START_LOCATION, END_LOCATION, ARRIVAL_EXTRA_NIGHTS, DEPARTURE_EXTRA_NIGHTS } = SPCheckoutFormFields;

const useSPSummaryInfo = () => {
  const { t } = useTranslation();
  const SPSelectHotelsPrice = useSPSelectHotelsPrice();
  const orderPrice = useSPOrderPrice();

  const isCheckout = useRouterMatch(CHECKOUT);
  const isSelectedHotels = useRouterMatch(SELECT_HOTELS);

  const mergedPrice =
    isCheckout || isSelectedHotels
      ? { ...orderPrice, ...SPSelectHotelsPrice }
      : { ...SPSelectHotelsPrice, ...orderPrice };

  const bookingType = useSelector(({ global, order }) => order.data?.build_type || global.bookingType);

  // bookingTypes.TOUR is for sp without accommodation
  if (
    bookingType &&
    ![bookingTypes.SMART_PACKAGE, bookingTypes.CUSTOM_PACKAGE, bookingTypes.TOUR].includes(bookingType)
  ) {
    return {
      lineItems: [],
      rooms: [],
      endLocation: null,
      totalPrice: null,
      requestedPrice: null,
    };
  }

  const {
    cities,
    end_locations: endLocations,
    start_locations: startLocations,
  } = useSelector((state) => state.smartPackage.data);

  const { watch } = useFormContext() || {};

  const rooms = watch ? watch("rooms") : 0;

  const startDate = watch(START_DATE);
  const startCityId = watch(START_LOCATION);
  const endLocation = watch(END_LOCATION);
  const endDate = useSelector(({ order }) => {
    return order.data?.package_end_date ? dates.convertDateToUTC(order.data?.package_end_date) : null;
  });
  const orderStartDate = useSelector(({ order }) => dates.convertDateToUTC(order.data?.package_start_date));
  const product_items = useSelector(({ order }) => order.data?.product_items);
  const lastTour = findLast(product_items, (el) => el.klass === "tour");
  const endCityId = lastTour?.dropoff_city_id;

  const startCityName = cities?.find((el) => Number(el.id) === Number(startCityId))?.name;
  const startLocationName = startLocations?.find((el) => Number(el.id) === Number(startCityId))?.name;
  const endCityName = cities?.find((el) => Number(el.id) === Number(endCityId))?.name;
  const endLocationName = endLocations?.find((el) => Number(el.id) === Number(endLocation))?.name;

  // Overnight stays
  const arrivalExtraNights = watch(ARRIVAL_EXTRA_NIGHTS) || 0;
  const departureExtraNights = watch(DEPARTURE_EXTRA_NIGHTS) || 0;
  const nights = useSelector(({ smartPackage }) => smartPackage.data?.nights);
  const showTipForExtraNights = useSelector(({ smartPackage }) => smartPackage.data?.skip_accommodation);
  const shouldRemoveExtraNights = isCheckout || isSelectedHotels;
  const displayStartDate = shouldRemoveExtraNights
    ? dates.sub(startDate, { days: arrivalExtraNights }, false)
    : orderStartDate;
  const displayEndDate = shouldRemoveExtraNights
    ? dates.add(startDate, { days: nights + departureExtraNights }, false)
    : endDate;

  const showRefoundPolicyWarn =
    startDate && dates.getNumberOfDaysFromToday(dates.sub(startDate, { days: arrivalExtraNights })) <= 30;

  const getLineItems = () => {
    const lineItems = [];

    if (mergedPrice.adults > 0) {
      lineItems.push({
        name: "adults",
        number: mergedPrice.adults,
        ...(!isCheckout && {
          pricePerItem: mergedPrice.price_per_adult,
          price: Math.ceil(mergedPrice.price_per_adult * Number(mergedPrice.adults)),
        }),
      });
    }

    if (mergedPrice.children > 0) {
      lineItems.push({
        name: "children",
        number: mergedPrice.children,
        ...(!isCheckout && {
          pricePerItem: mergedPrice.price_per_child,
          price: Math.ceil(mergedPrice.price_per_child * Number(mergedPrice.children)),
        }),
      });
    }

    return lineItems;
  };

  return {
    ...(!isCheckout && {
      totalPrice: mergedPrice.totalPrice,
      roundedTotalPrice: mergedPrice.roundedTotalPrice === "NaN" ? null : mergedPrice.roundedTotalPrice,
      requestedPrice: mergedPrice.requestedPrice,
      pricePerPerson: mergedPrice.price_per_person,
      hidePricesSection: mergedPrice.hidePricesSection,
    }),
    lineItems: getLineItems(),
    hints: [
      !isCheckout && !isSelectedHotels && showTipForExtraNights && t("summary.package.hint"),
      showRefoundPolicyWarn && t("refund.policy"),
    ].filter(Boolean),
    rooms,
    startLocation: {
      date: startDate ? displayStartDate : null,
      name: startLocationName ? startLocationName : startCityName,
    },
    ...(startDate &&
      displayEndDate && {
        endLocation: { date: displayEndDate, name: endLocationName ? endLocationName : endCityName },
      }),
  };
};

export default useSPSummaryInfo;
