import { useSelector } from "react-redux";

import { bookingTypes } from "../../../helpers/constants";
import { useUser } from "@tint_fe/hooks";

const useBundleSummary = () => {
  const { user } = useUser();
  const orderData = useSelector(({ order }) => order.data);
  const bookingType = orderData?.build_type;
  const product_items = orderData?.product_items;
  const price = useSelector(({ global }) => global.price);
  const requestedPrice = useSelector(({ order }) => order.dataForPayment.price_to_be_charged);

  const startDate = product_items?.[0]?.start_at;
  const startCity = product_items?.[0]?.pickup_point?.city_name;

  const endDate = product_items?.[product_items?.length - 1]?.end_at;
  const endCity = product_items?.[product_items?.length - 1]?.dropoff_point?.city_name;

  if (bookingType !== bookingTypes.BUNDLE) {
    return {
      lineItems: [],
    };
  }

  return {
    name: `Bundle ${orderData?.number} ${orderData?.customer?.last_name ? orderData?.customer?.last_name : ""}`,
    // lineItems: product_items.map((el) => ({ name: el.display_name })),
    citizenshipTaxAmount: price.rounded_citizenship_tax_amount,
    roundedTotalPrice: Math.ceil(Number(price.tint_charge_price || price.rounded_taxed_components_price)),
    agentFeeAmount: user?.isAgent ? price?.agent_fee_amount : 0,
    totalPrice: price.tint_charge_price,
    requestedPrice,
    currency: price?.charge_currency,
    startLocation: {
      date: startDate,
      ...(startCity && { name: startCity }),
    },
    ...(startDate && endDate && { endLocation: { date: endDate, name: endCity ? endCity : "" } }),
  };
};

export default useBundleSummary;
