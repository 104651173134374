import React, { useEffect } from "react";
import T from "prop-types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AppRoutes } from "./react/router";
import {
  useHistoryListen,
  useParseSearchParams,
  useReduxAction,
  useHandleFailPayment,
  useShowApiMessage,
} from "./hooks";
import { saveRootIdAction, getWidgetIntegrationRequest } from "./redux/global/globalActions";
import ContactUsModal from "./react/components/ContactUsModal";
import RequestQuoteModal from "./react/components/RequestQuoteModal";
import Theme from "./themes/Theme";

const App = ({ rootId, name }) => {
  const saveRootId = useReduxAction(saveRootIdAction);
  const getWidgetIntegration = useReduxAction(getWidgetIntegrationRequest);

  const { fail_payment, ...searchParams } = useParseSearchParams();

  useHistoryListen();
  useHandleFailPayment(fail_payment);

  useEffect(() => {
    saveRootId(rootId);
    getWidgetIntegration();
  }, []);

  useShowApiMessage();

  return (
    <Theme integration={name}>
      <AppRoutes searchParams={searchParams} integration={name} />
      <ContactUsModal />
      <RequestQuoteModal />
      <ToastContainer className="container" role="alert" />
    </Theme>
  );
};

App.propTypes = {
  rootId: T.string,
  name: T.string,
};

export default App;
