import React from "react";
import T from "prop-types";

import { parseClasses } from "@tint_fe/helpers";

import Radio from "./Radio";
import InputWrapper from "../Inputs/InputWrapper";

const RadioGroup = React.forwardRef(
  ({ label, radios, scrollIntoViewOnError, error, errorClasses, className, value, ...rest }, ref) => {
    const { mainCls } = parseClasses({
      base: "radio-group",
      additional: className,
      classes: [error && "error"],
    });

    return (
      <InputWrapper
        label={label}
        classNameWrapper={mainCls}
        errorClasses={errorClasses}
        scrollIntoViewOnError={scrollIntoViewOnError}
        withoutOverflowTooltip
        error={error}
      >
        {radios.map((radio) => (
          <>
            <Radio
              key={radio.value}
              value={radio.value}
              label={radio.label}
              selected={value === radio.value}
              {...rest}
              ref={ref}
              className={radio.value === "split" ? "radio__split_payment" : ""}
            />
          </>
        ))}
      </InputWrapper>
    );
  },
);

RadioGroup.propTypes = {
  radios: T.array,
  label: T.string,
  scrollIntoViewOnError: T.bool,
  error: T.string,
  errorClasses: T.string,
  className: T.string,
  value: T.string,
};

export default RadioGroup;
