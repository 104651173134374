import * as React from "react";
const TintProsHeart = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      d="M16.2072 9.70703C16.5977 9.3165 16.5977 8.68334 16.2072 8.29281C15.8167 7.90229 15.1835 7.90229 14.793 8.29281L11.0001 12.0857L9.70722 10.7928C9.3167 10.4023 8.68353 10.4023 8.29301 10.7928C7.90249 11.1833 7.90249 11.8165 8.29301 12.207L10.293 14.207C10.6835 14.5975 11.3167 14.5975 11.7072 14.207L16.2072 9.70703Z"
      fill="#21AFE4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.50521 3.54965C6.19252 1.25356 9.65298 1.67202 11.9929 3.71691C14.3301 1.67124 17.7537 1.28246 20.4709 3.54088C23.4445 6.01238 23.8016 10.2275 21.5118 13.1739C20.7168 14.197 19.2374 15.6827 17.7545 17.0968C16.255 18.5268 14.6963 19.9356 13.7042 20.8209L13.6822 20.8406C13.5197 20.9857 13.3571 21.1308 13.2072 21.2444C13.0387 21.3722 12.8318 21.5037 12.5679 21.5828C12.1958 21.6945 11.7907 21.6945 11.4186 21.5828C11.1547 21.5037 10.9478 21.3722 10.7793 21.2444C10.6294 21.1308 10.4669 20.9857 10.3043 20.8405L10.2823 20.8209C9.2902 19.9356 7.73154 18.5268 6.23203 17.0968C4.74915 15.6827 3.26977 14.197 2.47471 13.1739C0.176094 10.2161 0.598423 6.03328 3.50521 3.54965ZM4.80441 5.07021C6.818 3.34975 9.52828 3.79238 11.2333 5.78575C11.4233 6.00786 11.701 6.13573 11.9933 6.13573C12.2856 6.13573 12.5632 6.00786 12.7532 5.78575C14.4507 3.80119 17.1256 3.36105 19.1925 5.07898C21.3152 6.84319 21.5773 9.83034 19.9326 11.9467C19.2418 12.8357 17.8676 14.2253 16.3742 15.6494C14.8975 17.0577 13.3572 18.4501 12.3726 19.3287C12.1791 19.5013 12.0771 19.5914 11.9988 19.6507L11.9933 19.6549L11.9877 19.6507C11.9095 19.5914 11.8074 19.5013 11.6139 19.3287C10.6293 18.4501 9.08904 17.0577 7.61229 15.6494C6.11892 14.2253 4.74475 12.8357 4.05392 11.9467C2.41807 9.84171 2.7011 6.86733 4.80441 5.07021Z"
      fill="#21AFE4"
    />
  </svg>
);
export default TintProsHeart;
