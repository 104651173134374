import React from "react";
import { useSelector } from "react-redux";

import { useBreakPoints, useRouterMatch } from "@tint_fe/hooks";
import { bookingTypes, join } from "@tint_fe/helpers";

import TintProsBooking from "../../components/TintProsBooking";
import RoomsTimer from "../../components/RoomsTimer";
import { usePaymentLayout, useRedirectFromPayment } from "../../../hooks";
import { PAYMENT } from "../../router";
import { selectOrderExpiredTime } from "../../../redux/order/orderSelectors";
import { timeToShowTimer } from "../../../helpers/constants";
import OrderSummaryView from "../../components/OrderSummaryView";
import useBundleSummary from "./useBundleSummary";

const BundleSummary = () => {
  const { product_items = [] } = useSelector(({ order }) => order.data);
  const expiredTime = useSelector(selectOrderExpiredTime);
  const summary = useBundleSummary();
  const { isMd } = useBreakPoints();

  const isPaymentPage = useRouterMatch(PAYMENT);
  const isPaymentLayout = usePaymentLayout();
  const { goToPrevPath, paymentExpireAt } = useRedirectFromPayment();

  const bookingType = useSelector(({ global, order }) => global?.bookingType || order.data?.build_type);
  const preventShowNotify = bookingType === bookingTypes.TOUR;
  const showTimer = isPaymentPage;
  const renderTimer = product_items.some(({ hotel_line_items = [] }) => hotel_line_items.length > 0);

  const getTimerExpiredHandler = () => {
    return () => {
      if (isPaymentPage) {
        goToPrevPath();
      }
    };
  };

  return (
    <div className={join(["order-summary__sticky", isPaymentLayout && "payment-page"], "")}>
      {!isPaymentLayout && !isMd && <OrderSummaryView showTotal {...summary} />}

      {renderTimer && (
        <RoomsTimer
          onTimeExpired={getTimerExpiredHandler()}
          expireAt={isPaymentPage ? paymentExpireAt : expiredTime}
          timeToShow={isPaymentPage ? timeToShowTimer.PAYMENT : timeToShowTimer.ORDER}
          hideTimer={!showTimer}
          preventShowNotify={preventShowNotify ?? true}
        />
      )}

      <TintProsBooking {...(isPaymentLayout && { className: "payment-page" })} />
    </div>
  );
};

export default BundleSummary;
