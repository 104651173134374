import React, { useMemo } from "react";
import RcSelect, { components } from "react-select";
import T from "prop-types";

import { parseClasses, MAX_KIDS_AGE, checkIsIOS } from "@tint_fe/helpers";
import { useTranslation } from "@tint_fe/hooks";

const Option = ({ children, data, ...rest }) => {
  return (
    <components.Option {...rest}>
      <span className="main-text">{data.name || children} </span>
    </components.Option>
  );
};

Option.propTypes = {
  children: T.node,
  data: T.object,
};

const SelectChildAge = ({ value, onChange, label, minChildAge, maxChildAge, error }) => {
  const { t } = useTranslation();
  const { mainCls, elCls } = parseClasses({ base: "child-age-select", additional: ["select"] });

  const options = useMemo(() => {
    const optionsArr = [];

    for (let i = minChildAge; i <= maxChildAge; i++) {
      optionsArr.push({
        value: i,
        label: `${i} ${i === 1 ? t("year") : t("years")}`,
      });
    }

    return optionsArr;
  }, [t]);

  return (
    <div className={mainCls}>
      <span className={elCls("label")}>{label}</span>
      <div>
        <RcSelect
          value={options.find((el) => el.value === value)}
          classNamePrefix={`${checkIsIOS(true) ? "rc-select" : "win-rc-select rc-select"}`}
          className={`${error ? "error" : ""}`}
          pattern="[0-9]*"
          options={options}
          isSearchable={checkIsIOS()}
          onChange={(e) => onChange(e.value)}
          onInputChange={(val) => val.replace(/\s/g, "").replace(/[^0-9.]/g, "")}
          maxMenuHeight={150}
          components={{
            Option: Option,
            IndicatorSeparator: () => null,
            Input: (inputProps) => <components.Input {...inputProps} inputMode="numeric" />,
          }}
        />
        {error && <span className={elCls("error")}>{"Age is required"}</span>}
      </div>
    </div>
  );
};

SelectChildAge.defaultProps = {
  maxChildAge: MAX_KIDS_AGE,
};

SelectChildAge.propTypes = {
  value: T.number,
  onChange: T.func,
  label: T.string,
  minChildAge: T.number,
  maxChildAge: T.number,
  error: T.object,
};

export default SelectChildAge;
