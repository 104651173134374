import * as React from "react";

function Bookmark16() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M3.33398 5.2C3.33398 4.0799 3.33398 3.51984 3.55197 3.09202C3.74372 2.71569 4.04968 2.40973 4.426 2.21799C4.85383 2 5.41388 2 6.53398 2H9.46732C10.5874 2 11.1475 2 11.5753 2.21799C11.9516 2.40973 12.2576 2.71569 12.4493 3.09202C12.6673 3.51984 12.6673 4.0799 12.6673 5.2V14L8.00065 11.3333L3.33398 14V5.2Z"
        stroke="#21AFE4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Bookmark16;
