import {
  getItemListIdFromSessionStorage,
  getItemListIndexFromSessionStorage,
  getItemListNameFromSessionStorage,
  getItemViewIndexFromSessionStorage,
} from "../storages/sessionStorage";
import { getUserFromSessionStorage } from "../storages/userStorage";

export function initAnalytics() {
  window.dataLayer = window.dataLayer || [];
}

export function gtag(arg) {
  const user = getUserFromSessionStorage();

  window?.dataLayer?.push({
    ...arg,
    logged_in: !!user,
    ...(!!user && { user_name: `${user.first_name} ${user.last_name}`, user_email: user.email }),
  });
}

const get_session_item_list = () => {
  const item_list_id = getItemListIdFromSessionStorage();
  const item_list_name = getItemListNameFromSessionStorage();
  const item_list_index = getItemListIndexFromSessionStorage();
  const index = getItemViewIndexFromSessionStorage();

  return {
    item_list_id,
    item_list_name,
    item_list_index,
    index,
  };
};

const ga_get_shorten_product_klass = (klass) => {
  switch (klass) {
    case "tour":
      return "to";
    case "hotel":
      return "ho";
    case "activity":
      return "ac";
    case "package":
      return "pk";
    default:
      return "";
  }
};

const ga_get_number_of_nights = (nights) => {
  if (typeof nights === "number") {
    return String(nights);
  }

  return null;
};

const ga_begin_checkout_items = (product) => {
  const klass = product?.klass || "package";
  const nights = ga_get_number_of_nights(Number(product?.nights));
  const { item_list_id, item_list_name, item_list_index, index } = get_session_item_list();

  return [
    {
      item_id: product?.code,
      item_name: product?.display_name,
      // item_brand: 'american', // nationality is not defined
      // item_category2: "Jerusalem", // start point is not defined on begin checkout
      item_category3: ga_get_shorten_product_klass(klass),
      // item_category4: 'private', // not defined
      ...(nights && { item_category5: nights }),
      ...(item_list_id && { item_list_id }),
      ...(item_list_name && { item_list_name }),
      ...(item_list_index && { item_list_index }),
      ...(index && { index }),
    },
  ];
};

const ga_add_shipping_info_items = (product, order, price) => {
  const ga_begin_checkout = ga_begin_checkout_items(product);
  const paxes = order?.pax_quantity;

  return [
    {
      ...ga_begin_checkout[0],
      ...(paxes && { item_category: String(paxes) }),
      // item_category2: "Jerusalem", // start point is not defined on begin checkout
      // item_category4: 'private', // not defined
      ...(!!price && { price: Number(price) }),
    },
  ];
};

const ga_add_payment_info_items = (product, order, price) => {
  const ga_shipping_info = ga_add_shipping_info_items(product, order, price);

  return [
    {
      ...ga_shipping_info[0],
    },
  ];
};

export const ga_clear_previous_ecommerce = () => {
  gtag({ ecommerce: null });
};

export const ga_begin_checkout = (product) => {
  ga_clear_previous_ecommerce();
  const items = ga_begin_checkout_items(product);

  gtag({
    event: "begin_checkout",
    ecommerce: {
      items: items,
    },
  });
};

export const ga_change_hotel_click = () => {
  ga_clear_previous_ecommerce();

  gtag({ event: "change_hotel_click" });
};

export const ga_click_add_extra_nights = () => {
  ga_clear_previous_ecommerce();

  gtag({ event: "click_add_extra_nights" });
};

export const ga_change_hotel_select_click = () => {
  ga_clear_previous_ecommerce();

  gtag({ event: "change_hotel_select_click" });
};

export const ga_request_a_price_quote = () => {
  ga_clear_previous_ecommerce();

  gtag({ event: "request_a_price_quote" });
};

export const ga_start_hotel_step = (product) => {
  ga_clear_previous_ecommerce();
  const items = ga_begin_checkout_items(product);

  gtag({
    event: "start_hotel_step",
    ecommerce: {
      items: items,
    },
  });
};

export const ga_add_to_cart_extras = (product, order, price) => {
  const { item_list_id, item_list_name, item_list_index, index } = get_session_item_list();
  const items = ga_add_shipping_info_items(product, order, price);

  gtag({
    event: "add_to_cart",
    ecommerce: {
      items: [
        {
          ...items[0],
          add_to_cart_location: "tour_checkout_form", // currently only one
          extras_booking_step: "pre_booking",
          ...(item_list_id && { item_list_id }),
          ...(item_list_name && { item_list_name }),
          ...(item_list_index && { item_list_index }),
          ...(index && { index }),
        },
      ],
    },
  });
};

export const ga_add_to_cart_calendar = (product) => {
  const { item_list_id, item_list_name, item_list_index, index } = get_session_item_list();
  const items = ga_begin_checkout_items(product);

  gtag({
    event: "add_to_cart",
    ecommerce: {
      items: [
        {
          ...items[0],
          add_to_cart_location: "calendar",
          booking_step: "classic",
          ...(item_list_id && { item_list_id }),
          ...(item_list_name && { item_list_name }),
          ...(item_list_index && { item_list_index }),
          ...(index && { index }),
        },
      ],
    },
  });
};

export const ga_add_shipping_info = (product, order, price) => {
  ga_clear_previous_ecommerce();
  const items = ga_add_shipping_info_items(product, order, price);

  gtag({
    event: "add_shipping_info",
    ecommerce: {
      items: items,
    },
  });
};

export const ga_add_payment_info = (product, order, price, payment_type) => {
  ga_clear_previous_ecommerce();

  const items = ga_add_payment_info_items(product, order, price);
  gtag({
    event: "add_payment_info",
    ecommerce: {
      payment_type,
      items: items,
    },
  });
};

export const ga_purchase = (product, order, price, currency) => {
  ga_clear_previous_ecommerce();

  const items = ga_add_payment_info_items(product, order, price);
  gtag({
    event: "purchase",
    ecommerce: {
      transaction_id: order?.number,
      currency,
      ...(!!price && { value: Number(price) }),
      items: items,
    },
  });
};

export const ga_purchase_failure = (product, price, failure_message = "") => {
  gtag({
    event: "failure",
    name: "payment_failure",
    failure_message,
    item_id: product?.code,
    item_name: product?.display_name,
    ...(!!price && { price: Number(price) }),
  });
};
