import React from "react";
import T from "prop-types";

import { useControlledInput, useTranslation } from "@tint_fe/hooks";
import { checkCurrencyChar, dates } from "@tint_fe/helpers";
import { RadioGroup } from "../../../components";

const formatNextPaymentDate = (date) => {
  return dates.format(date, "dd LLL, yyyy");
};

const PartialPayment = ({
  control,
  onPartialPayment,
  second_payment_charge_date,
  chargeCurrency,
  partial_payments_enabled,
  partial_payment_amount,
  split_payment_enabled,
  split_payment_amount,
  ...rest
}) => {
  const { t } = useTranslation();
  const currencyChar = checkCurrencyChar(chargeCurrency);

  const { field } = useControlledInput({
    control,
    name: "partial_payment",
    defaultValue: "full",
  });

  const radios = [{ value: "full", label: t("paymentForm.partial.label.full") }];

  partial_payments_enabled &&
    radios.push({
      value: "partial",
      label: Number(partial_payment_amount)
        ? t("paymentForm.partialWithAmount.label.partial", {
            currencyChar: currencyChar,
            amount: partial_payment_amount,
            date: formatNextPaymentDate(second_payment_charge_date),
          })
        : t("paymentForm.partial.label.partial", { date: formatNextPaymentDate(second_payment_charge_date) }),
    });

  split_payment_enabled &&
    radios.push({
      value: "split",
      label: t("paymentForm.split.heading.split", { currency: currencyChar, amount: split_payment_amount }),
    });

  return (
    <RadioGroup
      label={t("paymentForm.partial.label")}
      radios={radios}
      {...field}
      onChange={(e) => {
        onPartialPayment && onPartialPayment(e.target.value);
        field.onChange(e);
      }}
      {...rest}
    />
  );
};

PartialPayment.propTypes = {
  control: T.object.isRequired,
  onPartialPayment: T.func,
  second_payment_charge_date: T.oneOfType([T.string, T.instanceOf(Date)]),
  chargeCurrency: T.string,
  split_payment_amount: T.oneOfType([T.number, T.string]),
  split_payment_enabled: T.bool,
  partial_payments_enabled: T.bool,
  partial_payment_amount: T.number,
};

export default PartialPayment;
