import React, { useState } from "react";
import T from "prop-types";
import { useTranslation } from "react-i18next";

import { useBreakPoints } from "@tint_fe/hooks";
import { checkCurrencyChar, join } from "@tint_fe/helpers";
import { ShoppingBasketSvg, ArrowRight } from "../../assets/icons";
import { Collapse } from "..";
import SummaryProductItems from "./SummaryProductItems";
import SummaryHotelsItems from "./SummaryHotelsItems";
import SummaryToursItems from "./SummaryToursItems";
import DiscountCode from "../DiscountCode/DiscountCode";
import SummaryRooms from "./SummaryRooms";
import SummaryLateBooking from "./SummaryLateBooking";
import SummaryBreakfast from "./SummaryBreakfast";

const SummaryDetailsCollapse = ({
  discountClassName,
  className,
  lineItems,
  lineItemsClassName,
  hotels,
  hotelsClassName,
  tours,
  toursClassName,
  rooms,
  roomsClassName,
  lateBookingSupplement,
  lateBookingSupplementClassName,
  roundedTotalPrice,
  partialPrice,
  splitPrice,
  openLabel,
  closeLabel,
  showDiscount,
  discountOpenByDefault,
  percent,
  discountAmount,
  discountCode,
  sendDiscountCode,
  isCustomPrice,
  requestedPrice,
  currency,
  agentFeeAmount,
  agentPaysAmount,
  breakfastIncluded,
}) => {
  const { isMd } = useBreakPoints();
  const { t } = useTranslation();
  const [sendingDiscount, setSendingDiscount] = useState();
  const currencyChar = checkCurrencyChar(currency);

  const [open, setOpen] = useState(false);
  const totalPrice =
    partialPrice || splitPrice || roundedTotalPrice || lineItems.reduce((sum, el) => sum + el.price, 0);

  const toggleCollapse = () => setOpen((prev) => !prev);

  const isRequestedPriceNearRounded = Math.abs(Number(requestedPrice) - Number(totalPrice)) <= lineItems.length * 1;
  const showRequestedPrice =
    (typeof roundedTotalPrice !== "undefined" &&
      typeof requestedPrice !== "undefined" &&
      !isRequestedPriceNearRounded &&
      !sendingDiscount &&
      Math.ceil(Number(requestedPrice)) !== Number(totalPrice)) ||
    partialPrice ||
    splitPrice;
  const sumBeforeDicount = Math.round(Number(roundedTotalPrice) + Number(discountAmount));

  const getPriceForDisplay = () => {
    let priceForDisplay = null;

    switch (true) {
      case showRequestedPrice:
        priceForDisplay = Math.ceil(Number(partialPrice || splitPrice || requestedPrice));
        break;
      case !!partialPrice:
        priceForDisplay = Math.ceil(Number(partialPrice));
        break;
      case !!splitPrice:
        priceForDisplay = Math.ceil(Number(splitPrice));
        break;
      case !!roundedTotalPrice && !Number.isNaN(Number(roundedTotalPrice)):
        priceForDisplay = Math.ceil(Number(roundedTotalPrice));
        break;
    }

    return priceForDisplay ? (
      <span className="bold">
        {currencyChar}
        {priceForDisplay}
      </span>
    ) : null;
  };

  const content = (
    <>
      {!!lineItems.length && <div className={"summary_title uppercase bold mg-b-2 mg-t-2"}>{"Prices"}</div>}
      <SummaryProductItems lineItems={lineItems} classNameItem={lineItemsClassName} currency={currency} />
      <SummaryRooms rooms={rooms} classNameItem={roomsClassName} />
      <SummaryHotelsItems hotels={hotels} classNameItem={hotelsClassName} />
      <SummaryToursItems tours={tours} classNameItem={toursClassName} />
      <SummaryLateBooking
        price={lateBookingSupplement}
        classNameItem={lateBookingSupplementClassName}
        currency={currency}
      />
      <SummaryBreakfast breakfastIncluded={breakfastIncluded} />
    </>
  );

  if (isMd && (lineItems?.length || hotels?.length || tours?.length)) {
    return (
      <Collapse
        className={className}
        onClick={isCustomPrice ? null : toggleCollapse}
        open={open}
        header={
          <span className={join(["head", "bold", open && "open"], " ")}>
            <ShoppingBasketSvg className="mg-r-2" />
            <span className="trim">{isCustomPrice ? t("summary.totalCharge") : open ? openLabel : closeLabel}</span>
            {!isCustomPrice && <ArrowRight className="arrow mg-l-1 " />}
          </span>
        }
        icon={getPriceForDisplay()}
      >
        {content}

        {!!roundedTotalPrice && (
          <div className={"summary__total-sum bold uppercase mg-t-3"}>
            <span>{t("summary.totalCharge")}</span>
            <div>
              {!!Number(percent) && showDiscount && (
                <span className={"summary__total-sum-before-discount"}>
                  {currencyChar}
                  {sumBeforeDicount}
                </span>
              )}
              <span>
                {currencyChar}
                {roundedTotalPrice}
              </span>
            </div>
          </div>
        )}

        {showRequestedPrice && (
          <div className={"summary__total-sum bold uppercase mg-t-4"}>
            <span>{t("summary.toBePaid")}</span>
            <span>
              {currencyChar}
              {Math.ceil(Number(partialPrice || splitPrice || requestedPrice))}
            </span>
          </div>
        )}

        {!!Number(agentFeeAmount) && (
          <div className={"summary__agent-fee-container"}>
            {!!Number(agentFeeAmount) && (
              <div className={"summary__agent-fee bold uppercase mg-t-2 mg-b-2"}>
                <span>{t("summary.agentFeeAmount")}</span>
                <span>
                  {currencyChar}
                  {Number(agentFeeAmount).toFixed(2)}
                </span>
              </div>
            )}
            {!!Number(agentPaysAmount) && (
              <div className={"summary__agent-fee bold uppercase mg-b-2"}>
                <span>{t("summary.agentPays")}</span>
                <span>
                  {currencyChar}
                  {Number(agentPaysAmount).toFixed(2)}
                </span>
              </div>
            )}
          </div>
        )}

        {showDiscount && (
          <DiscountCode
            className={discountClassName}
            percent={percent}
            discountCode={discountCode}
            openByDefault={discountOpenByDefault}
            setSendingDiscount={setSendingDiscount}
            sendDiscountCode={sendDiscountCode}
          />
        )}
      </Collapse>
    );
  }

  return content;
};

SummaryDetailsCollapse.defaultProps = {
  lineItems: [],
  hotels: [],
  tours: [],
  openLabel: "Hide order details",
  closeLabel: "Show order details",
  isCustomPrice: false,
  currency: "USD",
};

SummaryDetailsCollapse.propTypes = {
  discountClassName: T.string,
  className: T.string,
  lineItems: T.array,
  lineItemsClassName: T.string,
  lateBookingSupplement: T.number,
  lateBookingSupplementClassName: T.string,
  hotels: T.array,
  hotelsClassName: T.string,
  tours: T.array,
  toursClassName: T.string,
  roundedTotalPrice: T.oneOfType([T.number, T.string]),
  showDiscount: T.bool,
  discountOpenByDefault: T.bool,
  percent: T.oneOfType([T.number, T.string]),
  discountCode: T.string,
  discountAmount: T.number,
  sendDiscountCode: T.func,
  rooms: T.array,
  roomsClassName: T.string,
  partialPrice: T.number,
  splitPrice: T.number,
  openLabel: T.string,
  closeLabel: T.string,
  isCustomPrice: T.bool,
  requestedPrice: T.string,
  currency: T.string,
  agentFeeAmount: T.oneOfType([T.number, T.string]),
  agentPaysAmount: T.oneOfType([T.string, T.number]),
  breakfastIncluded: T.bool,
};

export default SummaryDetailsCollapse;
