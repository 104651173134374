import * as sharedTypes from "@tint_fe/redux-utils/src/global/globalActionTypes";

import * as types from "./globalActionTypes";
import * as orderTypes from "../order/orderActionTypes";
import * as productTypes from "../product/productActionTypes";
import * as smartPackageTypes from "../smartPackage/smartPackageTypes";
import { bookingTypes, steps } from "../../helpers/constants";
import { sortPriceItems } from "../helpers";

export const INIT_STATE = {
  integration: {},
  price: {},
  dropOffLocation: "",
  step: "",
  bookingType: "",
  searchQuery: {},
  loadingProduct: true,
  error: {},
  rootId: "",
  ratesWasExpired: {
    updating: false,
    data: null,
    // after filling "rateWasExpired" the object should look like this
    //   priceIsChanged: true,
    //   changedHotels: [{ id: 1, refreshed_name: "", start_at: "", end_at: "", name: "" }],Z
    //
  },
  openContactUsModal: false,
  openMoreInfoModal: false,
  openAllPoliciesModal: false,
  openRequestQuoteModal: false,
  selectedPaymentMethod: "",
  loadingFetchPrices: false,
  refetchPricesDueCurrencyChange: false,
  shouldPreselectSplitPayment: false,
};

const defineProductType = (klass) => {
  switch (klass) {
    case bookingTypes.HOTEL:
    case bookingTypes.ACTIVITY:
      return klass;
    default:
      return bookingTypes.TOUR;
  }
};

const globalReducer = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case types.GET_WIDGET_INTEGRATION_SUCCESS:
      return {
        ...state,
        integration: payload || {},
      };
    case productTypes.GET_PRODUCT_REQUEST:
    case smartPackageTypes.GET_SMART_PACKAGE_REQUEST:
      return {
        ...state,
        loadingProduct: true,
      };

    case smartPackageTypes.GET_SMART_PACKAGE_SUCCESS:
      return {
        ...state,
        bookingType: bookingTypes.SMART_PACKAGE,
        loadingProduct: false,
      };
    case productTypes.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        bookingType: defineProductType(payload.klass),
        loadingProduct: false,
      };
    case types.SET_LOADING_ACTION:
    case orderTypes.GET_ORDER_SUCCESS:
      return {
        ...state,
        loadingProduct: false,
      };

    case types.FETCH_PRICES_REQUEST:
      return {
        ...state,
        loadingFetchPrices: true,
      };

    case types.FETCH_PRICES_SUCCESS:
      return {
        ...state,
        price: sortPriceItems(payload),
        loadingFetchPrices: false,
      };

    case types.SET_DROP_OFF_LOCATION_ACTION:
      return {
        ...state,
        dropOffLocation: payload,
      };

    case orderTypes.GET_ORDER_FAILED:
    case productTypes.GET_PRODUCT_FAILED:
    case smartPackageTypes.GET_SMART_PACKAGE_FAILED:
    case orderTypes.ORDER_REQUEST_FAILED:
      return {
        ...state,
        loadingProduct: false,
        step: payload?.step || steps.ERROR,
        error: payload?.preventGlobalErrorRedirect ? {} : payload || { message: "Not found." },
      };

    case types.SET_ERROR_ACTION:
      return {
        ...state,
        loadingProduct: false,
        error: payload,
      };

    case types.SET_STEP_ACTION:
      return {
        ...state,
        step: payload,
      };

    case types.SET_BOOKING_TYPE:
      return {
        ...state,
        loadingProduct: false,
        bookingType: payload,
      };
    case types.UPDATE_RATES_PROCESSING:
      return {
        ...state,
        ratesWasExpired: {
          updating: true,
          data: null,
        },
      };
    case types.UPDATE_RATES_FAILED:
    case types.UPDATE_RATES_SUCCESS:
      return {
        ...state,
        ratesWasExpired: {
          data: payload,
          updating: false,
        },
      };
    case types.SHOW_MORE_INFO_MODAL:
      return {
        ...state,
        openMoreInfoModal: payload,
      };
    case types.SHOW_ALL_POLICIES_MODAL:
      return {
        ...state,
        openAllPoliciesModal: payload,
      };
    case types.SAVE_ROOT_ID:
      return {
        ...state,
        rootId: payload,
      };
    case sharedTypes.CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };

    case types.TOGGLE_CONTACT_US_MODAL:
      return {
        ...state,
        openContactUsModal: typeof payload === "boolean" ? payload : !state.openContactUsModal,
      };

    case types.TOGGLE_REQUEST_QUOTE_MODAL:
      return {
        ...state,
        openRequestQuoteModal: typeof payload === "boolean" ? payload : !state.openRequestQuoteModal,
      };

    case types.PARTIAL_PAYMENT_TOGGLE:
      return {
        ...state,
        price: payload,
      };

    case types.SAVE_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: payload,
      };

    case types.SET_REFETCH_PRICES_DUE_CURRENCY_CHANGE:
      return {
        ...state,
        refetchPricesDueCurrencyChange: payload,
      };
    case types.SET_SHOULD_PRESELECT_SPLIT_PAYMENT:
      return {
        ...state,
        shouldPreselectSplitPayment: payload,
      };

    default:
      return state;
  }
};

export default globalReducer;
