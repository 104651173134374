import React, { useEffect } from "react";
import T from "prop-types";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import TextField from "../../../../components/Form/TextField";
import TimePickerField from "../../../../components/Form/TimePickerField";
import SelectField from "../../../../components/Form/SelectField";
import HiddenField from "../../../../components/Form/HiddenField";
import ControllerInput from "../../../../components/Form/ControllerInput";
import checkIfIsCovidTest from "../../../../../helpers/checkIfIsCovidTest";
import { selectProductSettings, selectAvailablePickupPoints } from "../../../../../redux/product/productSelectors";
import { useTranslation } from "../../../../../hooks";
import { validationRule } from "../../../../../utils";

const StartTimeInput = ({ hideTimeField }) => {
  const { custom_pickup_point_strategy } = useSelector(selectProductSettings);
  const availablePickupPoints = useSelector(selectAvailablePickupPoints);

  const { t, isRtl } = useTranslation();
  const {
    control,
    formState: { isSubmitted },
    watch,
    setValue,
  } = useFormContext();
  const city_id = watch("city_id");
  const startDateValue = watch("start_date");
  const pickup_point_id = watch("pickup_point_id");
  const start_time = watch("start_time");
  const custom_pickup_point = watch("custom_pickup_point");

  const selectedPickupPoint = availablePickupPoints.find((x) => {
    return custom_pickup_point_strategy ? x.city_id === city_id : x.id === pickup_point_id;
  });

  const timesArray = (selectedPickupPoint?.raw_time_points || []).map((item) => {
    return item.replace(/:/g, " : ");
  });

  const { isCovidTest1, isCovidTest3, isCovidTest } = checkIfIsCovidTest();

  const isPickupPointSelected = !!(custom_pickup_point_strategy ? custom_pickup_point : pickup_point_id);

  const fieldLabel =
    custom_pickup_point?.name?.toLowerCase().includes("airport") ||
    selectedPickupPoint?.city_name?.toLowerCase().includes("airport") ||
    selectedPickupPoint?.name?.toLowerCase().includes("airport")
      ? "formField.pickupTime"
      : "formField.selectTime";

  const label = isCovidTest ? "test.time" : fieldLabel;

  useEffect(() => {
    if (hideTimeField && !start_time) {
      setValue("start_time", "00 : 00");
    }
  }, [hideTimeField, start_time]);

  useEffect(() => {
    if (timesArray.length === 1 && custom_pickup_point_strategy && custom_pickup_point?.name) {
      setValue("start_time", timesArray[0]);
    }
  }, [custom_pickup_point, city_id, timesArray.length]);

  switch (true) {
    case hideTimeField:
      return (
        <ControllerInput
          control={control}
          name="start_time"
          render={({ field }) => <HiddenField {...field} />}
          rules={{
            ...validationRule.required(label),
          }}
        />
      );
    case timesArray?.length > 1:
      return (
        <ControllerInput
          control={control}
          name="start_time"
          render={({ field, fieldState: { error } }) => {
            return (
              <SelectField
                isSubmitting={isSubmitted}
                disabled={!startDateValue || !isPickupPointSelected}
                isSearchable={false}
                placeholder={t("formField.selectTime")}
                label={t(label)}
                options={timesArray.map((x) => ({ value: x, label: x }))}
                errors={error?.message}
                {...((isCovidTest1 || isCovidTest3) && { remark: t("test.time.remark") })}
                {...field}
              />
            );
          }}
          rules={{
            ...validationRule.required(label),
          }}
        />
      );
    case timesArray?.length === 1 && start_time && isPickupPointSelected:
      return (
        <ControllerInput
          control={control}
          name="start_time"
          render={({ field, fieldState: { error } }) => {
            return (
              <TextField
                {...field}
                className="start-time"
                disabled={true}
                label={t(label)}
                errors={error?.message}
                dir={"auto"}
                {...(isRtl ? { style: { textAlign: "right" } } : {})}
              />
            );
          }}
          rules={{
            ...validationRule.required(label),
          }}
        />
      );
    default:
      return (
        <ControllerInput
          control={control}
          name="start_time"
          render={({ field, fieldState: { error } }) => {
            return (
              <TimePickerField
                className="form-group--wrapper"
                isSubmitting={isSubmitted}
                label={t(label)}
                placeholder={t("formField.selectTime")}
                disabled={!startDateValue || !isPickupPointSelected}
                errors={error?.message}
                {...((isCovidTest1 || isCovidTest3) && { remark: t("test.time.remark") })}
                {...field}
                dir={"auto"}
                {...(isRtl ? { style: { textAlign: "right" } } : {})}
              />
            );
          }}
          rules={{
            ...validationRule.required(label),
          }}
        />
      );
  }
};

StartTimeInput.propTypes = {
  hideTimeField: T.bool,
};

export default StartTimeInput;
