import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { useRouterMatch, useBreakPoints } from "@tint_fe/hooks";
import { join } from "@tint_fe/helpers";
import { useIntegrationCode } from "@tint_fe/redux-utils/hooks";

import TintProsBooking from "../../../components/TintProsBooking";
import RoomsTimer from "../../../components/RoomsTimer";
import { usePaymentLayout, useRedirectFromPayment, useDiscountInfo } from "../../../../hooks";
import { PAYMENT } from "../../../router";
import { selectAccommodationExpiredTime } from "../../../../redux/smartPackage/smartPackageSelectors";
import { selectOrderExpiredTime } from "../../../../redux/order/orderSelectors";
import { timeToShowTimer } from "../../../../helpers/constants";
import SPOrderSummary from "./SPOrderSummary";

const shouldPreventNotify = (skipAccommodation, arrival_extra_nights, departure_extra_nights) => {
  if ([skipAccommodation, arrival_extra_nights, departure_extra_nights].includes("undefined")) {
    return true;
  }
  const isOvernightsSelected = Number(arrival_extra_nights ?? 0) > 0 || Number(departure_extra_nights ?? 0) > 0;

  return skipAccommodation && !isOvernightsSelected;
};

const shouldPreventTimer = (preventNotification, isPayment) => {
  return isPayment ? false : preventNotification;
};

const SmartPackageSummary = () => {
  const { arrival_extra_nights, departure_extra_nights } = useSelector(({ order }) => order.data);
  const skipAccommodation = useSelector(({ smartPackage }) => smartPackage.data.skip_accommodation);

  const accommodationExpiredTime = useSelector(selectAccommodationExpiredTime);
  const expiredTime = useSelector(selectOrderExpiredTime);
  const integrationCode = useIntegrationCode();

  const { showDiscount } = useDiscountInfo();
  const { promotion_image_url = "" } = useSelector(({ global }) => global?.integration);
  const isPaymentPage = useRouterMatch(PAYMENT);
  const isPaymentLayout = usePaymentLayout();
  const { isMd } = useBreakPoints();
  const { goToPrevPath } = useRedirectFromPayment();

  const preventShowNotification = shouldPreventNotify(skipAccommodation, arrival_extra_nights, departure_extra_nights);
  const preventShowTimer = shouldPreventTimer(preventShowNotification, isPaymentPage);

  // In case both accommodationExpiredTime and expiredTime is not set
  const paymentExpireAt = useMemo(() => new Date().getTime() + timeToShowTimer.PAYMENT, [isPaymentPage]);
  const showTimer = isPaymentPage;

  const getTimerExpiredHandler = () => {
    if (isPaymentPage) {
      return () => {
        goToPrevPath();
      };
    }
  };

  return (
    <div className={join(["order-summary__sticky", (isPaymentLayout || isMd) && "payment-page"], "")}>
      {!isPaymentLayout && !isMd && <SPOrderSummary />}

      <RoomsTimer
        onTimeExpired={getTimerExpiredHandler()}
        expireAt={
          accommodationExpiredTime > new Date().getTime() ? accommodationExpiredTime : expiredTime ?? paymentExpireAt
        }
        timeToShow={isPaymentPage ? timeToShowTimer.PAYMENT : timeToShowTimer.ORDER}
        hideTimer={!showTimer || preventShowTimer}
        preventShowNotify={preventShowNotification ?? true}
      />

      {showDiscount && isMd && !!promotion_image_url && (
        <div className={"order-summary__svg-banner"}>
          <img src={promotion_image_url} alt="promotion" />
        </div>
      )}

      <TintProsBooking {...(isPaymentLayout && { className: "payment-page" })} integrationCode={integrationCode} />
    </div>
  );
};

export default SmartPackageSummary;
