import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { MAX_KIDS_AGE, validationRule } from "@tint_fe/helpers";
import { useBreakPoints } from "@tint_fe/hooks";

import { useReduxAction } from "@tint_fe/redux-utils";
import MobileSummary from "../../../components/BookingNav/MobileBookingSummary";
import FormContainer from "../../../components/Form/FormContainer";
import FormHeader from "../../../components/Form/FormHeader";
import { SPCheckoutFormFields } from "../helpers";
import {
  useCreateAutoAccommodationSPOrder,
  useGetAccomodation,
  useGetSPCheckoutFromData,
  useSkipAccommodation,
} from "../hooks";

import StickyButtonGroup from "../../../components/StickyButtonGroup";
import { scrollToError } from "../../../../utils";
import SPRoomsForm from "./SPRoomsForm";
import SPNumericField from "./SPNumericField";
import SPDatePiker from "./SPDatePiker";
import SelectMealPlan from "./SelectMealPlan";
import SPSelectCity from "./SPSelectCity";
import SPFlightNumber from "./SPFlightNumber";
import SPOvernights from "./SPOvernights";
import SPBucketLevel from "./SPBucketLevel";
import { SP_HOTEL_LEVELS } from "../../../../helpers/constants";
import { ga_begin_checkout } from "@tint_fe/helpers/services/google-analytics-v2";
import { setRefetchPricesDueCurrencyChange } from "../../../../redux/global/globalActions";
import { setSmartPackageSessionStorage } from "../../../../storage";

const createBucketLevelsForSelect = (levels = []) => {
  const bucketLevels = [];
  if (levels === null || !levels?.length) return SP_HOTEL_LEVELS;

  if (levels.includes("Capsule")) {
    bucketLevels.push({
      label: "hotelLevel.capsule.title",
      value: "Capsule",
    });
  }

  if (levels.includes("Budget")) {
    bucketLevels.push({
      label: "hotelLevel.budget.title",
      value: "Budget",
    });
  }

  if (levels.includes("Level 1")) {
    bucketLevels.push({
      label: "hotelLevel.level1.title",
      value: "Level 1",
    });
  }

  if (levels.includes("Level 2")) {
    bucketLevels.push({
      label: "hotelLevel.level2.title",
      value: "Level 2",
    });
  }

  if (levels.includes("Level 3")) {
    bucketLevels.push({
      label: "hotelLevel.level3.title",
      value: "Level 3",
    });
  }

  // TODO: Change in future
  // if (levels.includes("Level 1") || levels.includes("Level 2") || levels.includes("Level 3")) {
  //   bucketLevels.push({
  //     label: "hotelLevel.level1.title",
  //     value: "Level 1",
  //   });
  //   bucketLevels.push({
  //     label: "hotelLevel.level2.title",
  //     value: "Level 2",
  //   });
  //   bucketLevels.push({
  //     label: "hotelLevel.level3.title",
  //     value: "Level 3",
  //   });
  // }

  return bucketLevels;
};

const SPCheckoutForm = () => {
  const { t } = useTranslation();
  const setRefetchPrices = useReduxAction(setRefetchPricesDueCurrencyChange);
  const getSPCheckoutFromData = useGetSPCheckoutFromData();

  const fetching = useSelector((state) => state.smartPackage.fetching || state.order.loading);
  const product = useSelector((state) => state.smartPackage.data);
  const integrationName = useSelector((state) => state.global?.integration?.name);
  const productBucketLevels = product.bucket_levels;
  const allowEndCity = useSelector((state) => state.smartPackage.data.allow_end_city);
  const childrenAvailable = useSelector((state) => state.smartPackage.data.children_available);
  const startLocations = useSelector((state) => state.smartPackage.data?.start_locations);
  const endLocations = useSelector((state) => state.smartPackage.data?.end_locations);
  const packageNights = useSelector((state) => state.smartPackage.data?.nights ?? 0);
  const minChildAge = useSelector((state) => state.smartPackage.data?.min_child_age ?? 0);
  const maxChildAge = useSelector((state) => state.smartPackage.data?.max_child_age ?? MAX_KIDS_AGE);

  const { allowAccomodation } = useSkipAccommodation();
  const autoAccommodation = true;
  const { isMd } = useBreakPoints();

  const getAccomodation = useGetAccomodation();
  const createSPOrder = useCreateAutoAccommodationSPOrder();

  useEffect(() => {
    setTimeout(() => {
      ga_begin_checkout(product);
    }, 1000);
  }, []);

  const {
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const onSuccessSubmit = (values) => {
    if (autoAccommodation) {
      createSPOrder({ ...values });
    } else {
      setRefetchPrices(false);
      getAccomodation({ formData: { ...values, hotel_level: null } });
    }
    const dataToSave = getSPCheckoutFromData(values);
    setSmartPackageSessionStorage(dataToSave);
  };
  const onError = (e) => {
    console.error(e);
    scrollToError();
  };
  const onSubmit = handleSubmit(onSuccessSubmit, onError);

  return (
    <FormContainer
      loadingMessage={t("accommodationLoaderMessage")}
      loading={fetching}
      errors={errors}
      scrollLoaderIntoView
      useInformativeLoader
      integrationName={integrationName}
    >
      <SPDatePiker name={SPCheckoutFormFields.START_DATE} />
      <SPOvernights packageNights={packageNights} />
      <SPSelectCity
        label={t("formField.startFrom")}
        placeholder={t("formField.startLocation")}
        name={SPCheckoutFormFields.START_LOCATION}
        options={startLocations}
        typeLocationFieldName={SPCheckoutFormFields.START_LOCATION_TYPE}
        rules={{ ...validationRule.required("formField.startFrom") }}
      />
      <SPFlightNumber
        name="flight_number"
        label={t("formField.flightNumber.arrival")}
        dependOn={SPCheckoutFormFields.START_LOCATION}
      />
      {allowEndCity && (
        <>
          <SPSelectCity
            label={t("formField.endLocation")}
            placeholder={t("formField.endLocation")}
            name={SPCheckoutFormFields.END_LOCATION}
            typeLocationFieldName={SPCheckoutFormFields.END_LOCATION_TYPE}
            options={endLocations}
          />
          <SPFlightNumber
            name="departure_flight_number"
            label={t("formField.flightNumber.departure")}
            dependOn={SPCheckoutFormFields.END_LOCATION}
          />
        </>
      )}
      {allowAccomodation && (
        <>
          {autoAccommodation && (
            <SPBucketLevel
              fieldLabel={t("formField.bucketLevel")}
              placeholder={t("formField.bucketLevel.placeholder")}
              name={SPCheckoutFormFields.HOTEL_LEVEL}
              options={createBucketLevelsForSelect(productBucketLevels)}
            />
          )}
          <SelectMealPlan name={SPCheckoutFormFields.MEAL_PLAN} />
          <SPRoomsForm
            name={SPCheckoutFormFields.ROOMS}
            childrenNotAvailable={!childrenAvailable}
            minChildAge={minChildAge}
            maxChildAge={maxChildAge}
          />
        </>
      )}

      {!allowAccomodation && (
        <>
          <FormHeader text={t("numberOfPeople")} />
          <SPNumericField label={SPCheckoutFormFields.ADULTS} name={SPCheckoutFormFields.ADULTS} min={1} />

          {childrenAvailable && (
            <SPNumericField label={SPCheckoutFormFields.CHILDREN} name={SPCheckoutFormFields.CHILDREN} />
          )}
        </>
      )}
      {isMd && <MobileSummary />}
      <StickyButtonGroup loading={fetching} onSubmit={onSubmit} />
    </FormContainer>
  );
};

export default SPCheckoutForm;
