import * as React from "react";

const Bedtime = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M2.76212 9.65523V16.5518C2.76212 16.7347 2.68946 16.9101 2.56013 17.0394C2.43079 17.1688 2.25538 17.2414 2.07247 17.2414C1.88956 17.2414 1.71414 17.1688 1.58481 17.0394C1.45547 16.9101 1.38281 16.7347 1.38281 16.5518V9.65523C1.38281 9.47232 1.45547 9.29691 1.58481 9.16757C1.71414 9.03824 1.88956 8.96558 2.07247 8.96558C2.25538 8.96558 2.43079 9.03824 2.56013 9.16757C2.68946 9.29691 2.76212 9.47232 2.76212 9.65523Z"
      fill="#F48D2E"
    />
    <path d="M1.38281 9.65515H18.6242V15.862H1.38281V9.65515Z" fill="#F48D2E" />
    <path
      d="M2.76562 4.13798V8.27591H4.14494V7.58625C4.14494 7.40335 4.2176 7.22793 4.34693 7.09859C4.47627 6.96926 4.65168 6.8966 4.83459 6.8966H8.62769C8.8106 6.8966 8.98602 6.96926 9.11535 7.09859C9.24469 7.22793 9.31735 7.40335 9.31735 7.58625V8.28143H10.6967V7.58625C10.6967 7.40335 10.7693 7.22793 10.8987 7.09859C11.028 6.96926 11.2034 6.8966 11.3863 6.8966H15.1794C15.3623 6.8966 15.5377 6.96926 15.6671 7.09859C15.7964 7.22793 15.8691 7.40335 15.8691 7.58625V8.27591H17.2484V4.13798C17.2484 3.77216 17.1031 3.42133 16.8444 3.16266C16.5857 2.90399 16.2349 2.75867 15.8691 2.75867H4.14494C3.77912 2.75867 3.42829 2.90399 3.16962 3.16266C2.91094 3.42133 2.76563 3.77216 2.76562 4.13798Z"
      fill="#F48D2E"
    />
    <path
      d="M18.6215 9.65523V16.5518C18.6215 16.7347 18.5488 16.9101 18.4195 17.0394C18.2902 17.1688 18.1148 17.2414 17.9318 17.2414C17.7489 17.2414 17.5735 17.1688 17.4442 17.0394C17.3148 16.9101 17.2422 16.7347 17.2422 16.5518V9.65523C17.2422 9.47232 17.3148 9.29691 17.4442 9.16757C17.5735 9.03824 17.7489 8.96558 17.9318 8.96558C18.1148 8.96558 18.2902 9.03824 18.4195 9.16757C18.5488 9.29691 18.6215 9.47232 18.6215 9.65523Z"
      fill="#F48D2E"
    />
  </svg>
);

export default Bedtime;
