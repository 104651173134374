import * as React from "react";
const TintProsSmiley = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      d="M9 7.5C8.17157 7.5 7.5 8.17157 7.5 9C7.5 9.82843 8.17157 10.5 9 10.5C9.82843 10.5 10.5 9.82843 10.5 9C10.5 8.17157 9.82843 7.5 9 7.5Z"
      fill="#21AFE4"
    />
    <path
      d="M15 7.5C14.1716 7.5 13.5 8.17157 13.5 9C13.5 9.82843 14.1716 10.5 15 10.5C15.8284 10.5 16.5 9.82843 16.5 9C16.5 8.17157 15.8284 7.5 15 7.5Z"
      fill="#21AFE4"
    />
    <path
      d="M7.4 13.2C7.84044 12.8697 8.46468 12.9576 8.79686 13.3958L8.8015 13.4018C8.80745 13.4093 8.81877 13.4234 8.83531 13.4431C8.86846 13.4826 8.92219 13.5442 8.99543 13.6206C9.14272 13.7743 9.36407 13.9828 9.65067 14.1913C10.2277 14.6109 11.0255 15 12 15C12.9745 15 13.7723 14.6109 14.3493 14.1913C14.6359 13.9828 14.8573 13.7743 15.0046 13.6206C15.0778 13.5442 15.1315 13.4826 15.1647 13.4431C15.1812 13.4234 15.1925 13.4093 15.1985 13.4018L15.2031 13.3958C15.5353 12.9576 16.1596 12.8697 16.6 13.2C17.0418 13.5314 17.1314 14.1582 16.8 14.6L16 14C16.8 14.6 16.8002 14.5997 16.8 14.6L16.799 14.6013L16.7978 14.6029L16.7951 14.6065L16.7879 14.6159L16.7666 14.6432C16.7493 14.6649 16.726 14.6938 16.6966 14.7288C16.638 14.7987 16.555 14.8933 16.4486 15.0044C16.2365 15.2257 15.9266 15.5172 15.5257 15.8087C14.7277 16.3891 13.5255 17 12 17C10.4745 17 9.27227 16.3891 8.47433 15.8087C8.07343 15.5172 7.76353 15.2257 7.55145 15.0044C7.445 14.8933 7.36201 14.7987 7.30336 14.7288C7.27401 14.6938 7.25065 14.6649 7.23341 14.6432L7.21211 14.6159L7.2049 14.6065L7.20216 14.6029L7.20101 14.6013C7.20076 14.601 7.2 14.6 8 14L7.2 14.6C6.86863 14.1582 6.95817 13.5314 7.4 13.2Z"
      fill="#21AFE4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
      fill="#21AFE4"
    />
  </svg>
);
export default TintProsSmiley;
