import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { omit } from "lodash";

import { QueryString, parseClasses } from "@tint_fe/helpers";
import { useCheckIsDefaultIntegration, useReduxAction } from "@tint_fe/redux-utils/hooks";

import { useBreakPoints, useHistoryNavigation } from "../../../../hooks";
import { SMART_PACKAGE_SELECT_HOTELS, SMART_PACKAGE_CHECKOUT } from "../../../router";
import { setReplaceHotelIdAction, updateSelectedHotelsAction } from "../../../../redux/smartPackage/smartPackageAction";
import CustomerInfo from "../../CustomerInfo/CustomerInfo";
import HotelsItinerary from "./HotelsItinerary";
import { formatPiToSelectedHotel, getHotelsFromPi, getItemsWithHotels } from "../helpers";

const SmartPackageCustomerInfo = () => {
  const { replace, push } = useHistoryNavigation();
  const { mainCls, elCls } = parseClasses({ base: "customer-form-sp" });
  const { isMd } = useBreakPoints();

  const setReplaceHotelId = useReduxAction(setReplaceHotelIdAction);
  const setHotels = useReduxAction(updateSelectedHotelsAction);
  const product = useSelector((state) => state.smartPackage.data) || {};
  const order = useSelector((state) => state.order.data) || {};
  const isDefaultIntegration = useCheckIsDefaultIntegration();
  const overnightsSelected = order.arrival_extra_nights || order.departure_extra_nights;
  const productItems = order.product_items || [];
  const hotels = getItemsWithHotels(productItems);
  const missedBuckets = order?.missed_buckets || [];
  const allHotelItems = getHotelsFromPi(productItems);
  const selectedHotels = formatPiToSelectedHotel(allHotelItems);
  const autoAccommodation = true;

  const onBackFromCustomerInfoForm = useCallback(() => {
    const newSearch = QueryString.stringify(omit(QueryString.parse(document.search), "order"));

    const shouldNavToCheckout = (product.skip_accommodation && !overnightsSelected) || autoAccommodation;

    if (shouldNavToCheckout) {
      replace({ pathname: SMART_PACKAGE_CHECKOUT, search: newSearch });
    } else {
      replace({ pathname: SMART_PACKAGE_SELECT_HOTELS, search: document.search });
    }
  }, [product, order]);

  const onReplaceHotels = ({ hotel_id, pi_id }) => {
    const newSearch = QueryString.stringify(QueryString.parse(document.search), "order");
    setHotels(selectedHotels);

    setReplaceHotelId({ replaceHotelId: hotel_id, replaceProductItemId: pi_id });
    push({ pathname: SMART_PACKAGE_SELECT_HOTELS, search: newSearch });
  };

  return (
    <>
      {autoAccommodation && isMd && (
        <div className={mainCls}>
          <HotelsItinerary
            hotels={hotels}
            onReplace={onReplaceHotels}
            missedBuckets={missedBuckets}
            hotelLevel={order.hotel_level}
          />
          <span className={elCls("hr")} />
          <span className={elCls("title")}>{"Personal information"}</span>
        </div>
      )}
      <CustomerInfo onBack={onBackFromCustomerInfoForm} hideBack={isDefaultIntegration} />
    </>
  );
};

export default SmartPackageCustomerInfo;
