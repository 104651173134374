import React from "react";
import T from "prop-types";
import { useTranslation } from "react-i18next";

import SmartPackageSummary from "../SmartPackageSummary/SPSummaryContainer";
import { useBreakPoints, useDiscountInfo } from "../../../../hooks";
import { useSelector } from "react-redux";

const SmartPackageLayout = ({ children }) => {
  const { isRtl } = useTranslation();
  const { showDiscount } = useDiscountInfo();
  const { promotion_image_url = "" } = useSelector(({ global }) => global?.integration);
  const { isMd } = useBreakPoints();

  return (
    <div
      data-testid="smart-package-router"
      dir={isRtl ? "rtl" : "auto"}
      className={`row order-summary__sp-wrapper ${isRtl}`}
    >
      <div className="col col-12 col-md-7 order-summary__sp-content">
        {children}
        {showDiscount && !isMd && !!promotion_image_url && (
          <div className={"order-summary__svg-banner"}>
            <img src={promotion_image_url} alt="promotion" />
          </div>
        )}
      </div>
      <div className="col col-12 col-md-5 order-summary__sp-summary">
        <SmartPackageSummary />
      </div>
    </div>
  );
};

SmartPackageLayout.propTypes = {
  children: T.any.isRequired,
};

export default SmartPackageLayout;
