import React from "react";
import T from "prop-types";
import { DayPicker as RcDayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import ABDay from "./ABDay";

import en_US from "date-fns/locale/en-US";
import de_DE from "date-fns/locale/de";
import fr_FR from "date-fns/locale/fr";
import it_IT from "date-fns/locale/it";
import es_ES from "date-fns/locale/es";
import he_IL from "date-fns/locale/he";

import { dates } from "@tint_fe/helpers";
import { useBreakPoints, useTranslation } from "@tint_fe/hooks";

const LOCALES_MAP = {
  en: en_US,
  de: de_DE,
  fr: fr_FR,
  it: it_IT,
  es: es_ES,
  he: he_IL,
};

const ABDayPicker = ({
  numberOfMonths,
  lastSeatsDate,
  popularDate,
  spotlightedDate,
  minPrices,
  currencyChar,
  handleMinPrices,
  onMonthChange,
  ignoreI18,
  ignoreRtl,
  loading,
  handleUnavailableDates,
  ...rest
}) => {
  const { isRtl, i18n } = useTranslation();
  const { isMd } = useBreakPoints();
  const defaultNumberOfMonths = !isMd ? 2 : 1;

  return (
    <RcDayPicker
      dir={isRtl && !ignoreRtl ? "rtl" : "auto"}
      style={{ textAlign: "center" }}
      numberOfMonths={numberOfMonths || defaultNumberOfMonths}
      locale={ignoreI18 ? LOCALES_MAP.en : LOCALES_MAP[i18n.language] || LOCALES_MAP.en}
      formatters={{
        formatWeekdayName: (d) => dates.format(d, "ccc", false, "en"),
        formatCaption: (d) => dates.format(d, "MMMM yyyy", false, "en"),
      }}
      components={{
        // eslint-disable-next-line react/prop-types
        Day: ({ date, displayMonth }) => {
          const formattedDate = dates.format(date, "yyyy-MM-dd", false);
          const minPrice = minPrices.hasOwnProperty(formattedDate) ? minPrices[formattedDate].min_price : null;

          return (
            <ABDay
              date={date}
              displayMonth={displayMonth}
              isLastSeats={!!lastSeatsDate && dates.isSameDay(lastSeatsDate, date, false)}
              isPopular={!!popularDate && dates.isSameDay(popularDate, date, false)}
              isSpotlighted={!!spotlightedDate && dates.isSameDay(spotlightedDate, date, false)}
              minPrice={minPrice}
              currencyChar={currencyChar}
              handleMinPrices={handleMinPrices}
              isMd={isMd}
              loading={loading}
              handleUnavailableDates={
                typeof handleUnavailableDates === "function" && dates.isAfter(date, new Date(), false)
                  ? () => handleUnavailableDates(date)
                  : null
              }
            />
          );
        },
      }}
      onMonthChange={typeof onMonthChange === "function" ? (date) => onMonthChange(date, numberOfMonths) : null}
      disableNavigation={loading}
      {...rest}
    />
  );
};

ABDayPicker.defaultProps = {
  fromMonth: new Date(),
  fixedWeeks: true,
  pagedNavigation: true,
  lastSeatsDate: "",
  spotlightedDate: "",
  popularDate: "",
  minPrices: {},
};

ABDayPicker.propTypes = {
  fromMonth: T.instanceOf(Date),
  month: T.instanceOf(Date),
  fixedWeeks: T.bool,
  pagedNavigation: T.bool,
  numberOfMonths: T.number,
  lastSeatsDate: T.oneOfType([T.instanceOf(Date), T.string]),
  spotlightedDate: T.oneOfType([T.instanceOf(Date), T.string]),
  popularDate: T.oneOfType([T.instanceOf(Date), T.string]),
  minPrices: T.object,
  currencyChar: T.string,
  handleMinPrices: T.bool,
  onMonthChange: T.func,
  ignoreI18: T.bool,
  ignoreRtl: T.bool,
  loading: T.bool,
  handleUnavailableDates: T.func,
};

export default ABDayPicker;
